import { ColumnsType } from 'antd/es/table';
import { Modal, Table } from 'antd';

import React, { Dispatch, FC, SetStateAction } from 'react';
import { IClientDetailsApiResponse, IGstInfo } from '../../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';

interface IGSTInfoModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  detailsData: IClientDetailsApiResponse | null;
}

const GSTInfoModal: FC<IGSTInfoModal> = ({ isModalOpen, setIsModalOpen, detailsData }) => {
  const gstInfoTableColumns: ColumnsType<IGstInfo> = [
    {
      title: 'GST Number',
      dataIndex: 'gstNumber',
      width: 200,
      render: (num) => <GetCopyTextDiv text={num} />
    },
    {
      title: 'GST Address',
      dataIndex: 'gstAddress',
      width: 300,
      render: (address) => (
        <div>
          <div className="flex gap-3">
            <p className="flex min-w-[50px] justify-between font-bold">
              Line 1 <span>:</span>
            </p>
            <p>{address?.line1}</p>
          </div>

          <div className="flex gap-3">
            <p className="flex min-w-[50px] justify-between font-bold">
              Line 2 <span>:</span>
            </p>
            <p>{address?.line2}</p>
          </div>

          <div className="flex gap-3">
            <p className="flex min-w-[50px] justify-between font-bold">
              Locality <span>:</span>
            </p>
            <p>{address?.locality}</p>
          </div>

          <div className="flex gap-1">
            {`${address?.state} (${address?.stateCode}) - ${address?.postalCode}.`}
          </div>
        </div>
      )
    },
    {
      title: 'Billing Address',
      dataIndex: 'billingAddress',
      width: 300,
      render: (address) => (
        <div>
          <div className="flex gap-3">
            <p className="flex min-w-[50px] justify-between font-bold">
              Line 1 <span>:</span>
            </p>
            <p>{address?.line1}</p>
          </div>

          <div className="flex gap-3">
            <p className="flex min-w-[50px] justify-between font-bold">
              Line 2 <span>:</span>
            </p>
            <p>{address?.line2}</p>
          </div>

          <div className="flex gap-1">
            {`${address?.city}, ${address?.state} (${address?.stateCode}), ${address?.country}.`}
          </div>
        </div>
      )
    }
  ];

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal title="GST Info" open={isModalOpen} onCancel={onClose} footer={false} width={800}>
      <Table
        scroll={{ x: 700 }}
        columns={gstInfoTableColumns}
        rowClassName={'align-top'}
        dataSource={detailsData?.clientDetails?.gstInfo}
      />
    </Modal>
  );
};

export default GSTInfoModal;
