import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Modal, Select, Divider, message } from "antd";
import { useParams } from "react-router-dom";
import { cartList, deleteOrder, generateBulk, getUserDetails} from "../api";
import { useNavigate } from "react-router-dom";
import { ICartListResponse, RowSelection } from "../../../../interfaces/cartList";
import { TableColumns } from './TableColumns';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';


const BookingReq: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [cartListLoading, setCartListLoading] = useState<boolean>(false);
  const [cartListData, setCartListData] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(500);
  const [users, setUsers] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }]);
  const [selectedOrderId, setSelectedOrderId] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { cartTableColumns, addressDetailsColumns, packageDetailsColumns } = TableColumns();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDeleteOrderId, setSelectedDeleteOrderId] = useState<string | null>(null);
  const [serviceType, setServiceType] = useState('');
  const [serviceProvider, setServiceProvider] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [allOrders, setAllOrders] = useState<ICartListResponse[]>([]);

  const handleClose = () => {setShowModal(false);
    setSelectedService(null);
    setSelectedProvider(null);
  }

  useEffect(() => {
    getCart();
  }, [userId]);

  const getCart = async () => {
    try {
      if (!userId) return; 
      const cartListApiRes = await cartList({ pageNumber, pageLimit, userId });
      console.log(cartListApiRes, "API Response");
      setCartListData(cartListApiRes.list); 
      setAllOrders(cartListApiRes.list); 
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        console.log("User Details:", response);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };

  const refreshData = async () => {
    if (userId) {
      setCartListLoading(true);
      await getCart();
      setCartListLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const rowSelection: RowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[], selectedRows: ICartListResponse[]) => {
      setSelectedOrderId(selectedRowKeys.map((key) => String(key)));
      console.log('Selected Orders rowkey:', selectedRowKeys,79);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(selected,84)
      if (selected) {
        setSelectedRowKeys((prevKeys) => Array.from(new Set([...prevKeys, record.orderId])));
      } else {
        setSelectedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.orderId));
      }
    },
    onSelectAll: (selected: boolean, selectedRows: ICartListResponse[], changeRows: ICartListResponse[]) => {
      console.log(selectedRows,92)
      if (selected) {
        const newKeys = changeRows.map((row) => row.orderId);
        setSelectedRowKeys((prevKeys) => Array.from(new Set([...prevKeys, ...newKeys])));
        setSelectAll(selected);
      } else {
        const newKeys = changeRows.map((row) => row.orderId);
        setSelectedRowKeys((prevKeys) => prevKeys.filter((key) => !newKeys.includes(key.toString())));
        setSelectAll(false);
      }
    }
  };

  const handleConfirmUpload = async () => {
    if (!selectedService) {
      message.warning('Please select a service type.');
      return;
    }
    if (!selectedProvider) {
      message.warning('Please select a service provider.');
      return;
    }
    if (!selectAll && selectedOrderId.length === 0) {
      message.warning('Please select at least one order.');
      console.log('Selected Orders:', selectedOrderId,116);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        userId,
        serviceType: selectedService,
        serviceProvider: selectedProvider,
        orderIds: selectedOrderId,
        selectAll,
      };
        console.log(payload,136);

      const response = await generateBulk(payload);

      message.success(`Bulk generate successfully.`);
      await getCart();
      handleClose();
    } catch (error) {
      message.error('Error generating bulk');
      console.error('Bulk generation error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadConfirmation = () => {
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalConfirm = async () => {
    try {

      if (!serviceType || !serviceProvider || selectedOrders.length === 0) {
        message.error('Please select all fields');
        return;
      }
      const payload = {
        userId,
        serviceType,
        serviceProvider,
        orderIds: selectedOrders,
        selectAll,
      };
    console.log(payload,173);

      const response = await generateBulk(payload);
      message.success(`Bulk generate successful: ${response.message}`);
    } catch (error) {
      message.error('Error generating bulk');
    }
  };

  const serviceTypeOptions: {
    label: string;
    value: string;
  }[] = [
      {
        label: 'SELF_DROP', value: 'SELF_DROP'},
      {
      label: 'REGISTERED_POST', value: 'REGISTERED_POST'},
      {
      label: 'RPAD',value: 'RPAD' },
      {
      label: 'ORDINARY_POST',value: 'ORDINARY_POST'},
    ];
  const ProviderOptions: {
    label: string;
    value: string;
  }[] = [
      {
      label: 'INDIA_POST', value: 'INDIA_POST'},

    ];

  const onCloseDelete = () => {
    setOpenDelete(false);
    setSelectedDeleteOrderId(null);
  };

  const onDelete = async () => {
    if (!selectedDeleteOrderId) {
      message.error("No order selected for deletion.");
      return;
    }
    console.log("Deleting order with ID:", selectedDeleteOrderId);
    try {

      await deleteOrder(selectedDeleteOrderId);
      message.success("Order deleted successfully.");
      onCloseDelete();

      setCartListData(prevData => prevData.filter(item => item.orderId !== selectedDeleteOrderId));
    } catch (error) {
      message.error("Failed to delete order.");
      console.error("Delete error:", error);
    }
  };

  cartTableColumns.push({
    title: 'Delete',
    key: 'delete',
    dataIndex: 'delete',
    width: 100,
    align: 'center',
    fixed: 'right',
    render: (_: any, record: any) => {
      const showDeleteModal = () => {
        setSelectedDeleteOrderId(record.orderId);
        setOpenDelete(true);
      };
      return (
        <>
          <DeleteOutlined style={{ color: '#1890ff' }} onClick={showDeleteModal} />
          <Modal open={openDelete} onCancel={onCloseDelete} footer={null}   maskStyle={{ backgroundColor: 'transparent' }}  
             >
            <div>
              <p>Are you sure you want to delete this order?</p>
              <div className="my-5 flex justify-end gap-5">
                <Button onClick={onCloseDelete}>Cancel</Button>
                <Button onClick={onDelete} type="primary">
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </>
      );
    }
  });
  return (
    <div>
      <Button type="link" onClick={() => navigate("/userlist-finx")}>
        {"<"}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="mb-4 flex items-center justify-between">
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <h2 className="text-xl font-normal m-0">
              Booking Request for:{" "}
              {userData && userData.firstName ? (
                `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
              ) : (
                "Loading..."
              )}
            </h2>
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="flex items-center" style={{gap:"10px"}}>
            <Button size="large" onClick={refreshData}  icon={<ReloadOutlined className="mb-2"/>}>
              Refresh
            </Button>
            <div>
              <Button size="large"
              className="m-0" 
                onClick={() => navigate(`/upload-booking-finx/${userId}`)} >
                Upload
              </Button>
            </div>
            <Button
              className="m-0"
              type="primary"
              size="large"
              onClick={() => navigate(`/generate-bulk-finx/${userId}`, {
                state: {
                  orderCount: selectedRowKeys.length,
                  selectedOrderIds: selectedRowKeys,
                  totalOrders: allOrders.length,
                  selectAll
                }
              })}
            >
              Generate Bulk 
            </Button>
            <Modal
              title="Confirm Bulk Generation"
              visible={showModal}
              onOk={handleModalConfirm}
              onCancel={handleModalCancel}
              okText="Generate"
              cancelText="Cancel"   >
              <p>Are you sure you want to generate bulk for the selected orders?</p>
            </Modal>
          </div>
        </div>
      </div>

      <Table
        rowSelection={rowSelection}
        columns={cartTableColumns}
        dataSource={cartListData}
        loading={cartListLoading}
        rowKey={'orderId'}
        expandable={{
          expandedRowRender: (record) => {
            const addressData = [
              {
                ...record?.billingDetails,
                rowHeading: 'Billing Address'
              },
              {
                ...record?.deliveryDetails,
                rowHeading: 'Delivery Address'
              }
            ];
            return (
              <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
                <div>
                  <h2 className="text-xl font-bold m-0">Cart Details:</h2>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Package Details</h2>
                  <Table
                    columns={packageDetailsColumns}
                    dataSource={record?.packageDetails}
                    loading={cartListLoading}
                    rowKey={'rowHeading'}
                  />
                </div>
                <Divider className="my-5" />
                <div>
                  <h2 className="text-xl font-normal text-center m-0 mb-4">Address Details</h2>
                  <Table columns={addressDetailsColumns} dataSource={addressData} loading={cartListLoading} rowKey={'rowHeading'} />
                </div>
              </div>
            );
          }
        }}
      />
      <Modal title="Select ServiceType" visible={showModal} onCancel={handleClose} footer={null}>

        <Select
          placeholder="Select ServiceProvider"
          className="mt-5"
          style={{ width: '400px' }}
          options={ProviderOptions}
          onChange={(value) => setSelectedProvider(value)}
          value={selectedProvider}  />
        <Select
          placeholder="Select ServiceType"
          className="mt-5"
          style={{ width: '400px' }}
          options={serviceTypeOptions}
          onChange={(value) => setSelectedService(value)}
                value={selectedService}  />
        <div className="flex justify-end mt-5">
          <Button onClick={handleClose} style={{ marginRight: '10px' }}>
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} type="primary">
            Confirm
          </Button>
        </div>
      </Modal>


    </div>
  );
};

export default BookingReq;









