import React, { useState } from "react";
import { Drawer, Form, Input, InputNumber, Button } from "antd";
import { CreatefixedChargesAPI } from "../api";

interface FixedChargeDrawerProps {
  visible: boolean;
  onClose: () => void;
  userId: string;
  refreshData: () => void;
  users: { label: string; value: string }[];
}

const FixedChargeDrawer: React.FC<FixedChargeDrawerProps> = ({ visible, onClose, userId, refreshData, users }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const body = { ...values, userId };
      await CreatefixedChargesAPI(body);
      await refreshData();
    } catch (error) {
      console.error("Error creating fixed charge:", error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleValuesChange = () => {
    const amount = parseFloat(form.getFieldValue("amount"));
    if (!isNaN(amount)) {
      const taxValue = parseFloat((amount * 0.18).toFixed(2)); // Tax is 18%
      const total = parseFloat((amount + taxValue).toFixed(2));

      form.setFieldsValue({
        tax: taxValue,
        totalAmount: total,
      });
    }
  };

  return (
    <Drawer
      title="Add Fixed Charge"
      width={720}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={handleValuesChange}>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Form.Item
              name="noticeType"
              label="Notice Type"
              rules={[{ required: true, message: "Please enter notice type" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="pageies"
              label="Number of Pages"
              rules={[{ required: true, message: "Please enter number of pages" }]}
            >
              <InputNumber />
            </Form.Item>
          </div>
          <div className="flex gap-2">
            <Form.Item
              name="serviceType"
              label="Service Type"
              rules={[{ required: true, message: "Please enter service type" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="provider"
              label="Provider"
              rules={[{ required: true, message: "Please enter provider" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="flex gap-2">
            <Form.Item
              name="amount"
              label="Amount (without tax)"
              rules={[{ required: true, message: "Please enter amount" }]}
            >
              <Input style={{ width: "60%" }} />
            </Form.Item>
            <Form.Item name="tax" label="Tax">
              <InputNumber disabled style={{ width: "50%" }} />
            </Form.Item>
            <Form.Item name="totalAmount" label="Total Amount">
              <InputNumber disabled style={{ width: "50%" }} />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FixedChargeDrawer;
