import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Button, Empty } from 'antd';

import EmailVerificationForm from './EmailVerificationForm';
import { IFranchisee } from '../../../interfaces/ecboBulkOrder';
import BatchesTable from './BatchesTable';
import GetCopyTextDiv from '../../CommonComponents/GetCopyTextDiv';
import GenerateDockets from '../ECBOBulkOrders/Partials/GenerateDockets';
import { ArrayToExcelCsv } from '../../../utils/Excel-Conversion/ArrayToExcelCsv';
import ECBORegistration from './ECBORegistration';

const ECBOBatches = () => {
  const [batchesData, setBatchesData] = useState<IFranchisee[] | null>(null);
  const [enteredEmailId, setEnteredEmailId] = useState<string>('');
  const [generateDocketModal, setGenerateDocketModal] = useState<boolean>(false);
  const [docketsResponse, setDocketsResponse] = useState<string[]>([]);
  const [registrationModal, setRegistrationModal] = useState<boolean>(false);

  const generateDocket = () => {
    ArrayToExcelCsv({ data: docketsResponse, filename: 'Dockets' });
  };

  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-normal m-0">ECBO Batches</h2>

          <Button className="primary-button" onClick={() => setRegistrationModal(true)}>
            Register
          </Button>
        </div>

        <EmailVerificationForm
          setBatchesData={setBatchesData}
          setEnteredEmailId={setEnteredEmailId}
        />

        {!!(enteredEmailId && batchesData?.length) && (
          <div className="flex gap-3 my-2 justify-center">
            <Button type="primary" onClick={() => setGenerateDocketModal(true)}>
              Generate Dockets
            </Button>
            {!!docketsResponse?.length && (
              <Button icon={<FaDownload />} className="primary-button" onClick={generateDocket}>
                Download Docket
              </Button>
            )}
          </div>
        )}

        {enteredEmailId && (
          <GenerateDockets
            userId={batchesData?.length ? batchesData[0]?.userId : ''}
            enteredEmail={enteredEmailId}
            generateDocketModal={generateDocketModal}
            setGenerateDocketModal={setGenerateDocketModal}
            docketsResponse={docketsResponse}
            setDocketsResponse={setDocketsResponse}
            generateDocket={generateDocket}
          />
        )}
        {enteredEmailId && (
          <div>
            <p className="text-xl flex gap-3">
              <span>Email:</span>
              <span className="underline underline-offset-4">
                <GetCopyTextDiv text={enteredEmailId} />
              </span>
            </p>
            {batchesData ? (
              <BatchesTable batchesData={batchesData} />
            ) : (
              <Empty
                className="mt-5"
                description={
                  <p className="text-xl">
                    To data found for <b>{enteredEmailId}</b>
                  </p>
                }
              />
            )}
          </div>
        )}
      </div>

      <ECBORegistration
        registrationModal={registrationModal}
        setRegistrationModal={setRegistrationModal}
      />
    </>
  );
};

export default ECBOBatches;
