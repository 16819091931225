import { Button, Empty, Form, Input, Modal, Pagination, PaginationProps, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { getBusinessAddresses } from '../../api';
import { IPickupAddressDetails, IPickupAddressItem } from '../../../../../interfaces/b2bBulkOrder';
import AddressCard from '../AddressCard';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { BiPlusCircle } from 'react-icons/bi';
import AddNewAddress from '../AddNewAddress';
import { SearchOutlined } from '@ant-design/icons';

interface ISelectPickupAddressModal {
  pickupAddressModal: boolean;
  setPickupAddressModal: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  selectedAddress: IPickupAddressItem | null;
  setSelectedAddress: Dispatch<SetStateAction<IPickupAddressItem | null>>;
}

const SelectPickupAddressModal: FC<ISelectPickupAddressModal> = ({
  pickupAddressModal,
  setPickupAddressModal,
  businessId,
  selectedAddress,
  setSelectedAddress
}) => {
  const [addressList, setAddressList] = useState<IPickupAddressDetails | null>(null);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [enteredPincode, setEnteredPincode] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [addAddressModal, setAddAddressModal] = useState<boolean>(false);

  const handleGetPickupAddress = async () => {
    await getBusinessAddresses({
      setLoading: setModalLoading,
      setData: setAddressList,
      businessId: businessId,
      postalCode: enteredPincode,
      pageLimit: pageLimit,
      currentPage: currentPage
    });
  };

  useEffect(() => {
    if (pickupAddressModal) {
      handleGetPickupAddress();
    }
  }, [pickupAddressModal, enteredPincode, pageLimit, currentPage]);

  const handleSelectAddress = (address: IPickupAddressItem) => {
    setSelectedAddress(address);
    setPickupAddressModal(false);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const onSearchPincode = ({ searchPincode }: { searchPincode: string }) => {
    setEnteredPincode(searchPincode);
  };

  return (
    <>
      <Modal
        width={'80%'}
        open={pickupAddressModal}
        onCancel={() => setPickupAddressModal(false)}
        title="Select Pickup Address"
        footer={false}>
        <Formlabel labelText={'Search Pincode'} />

        <div className="flex w-full mt-3 gap-2">
          <Form className="w-[90%]" onFinish={onSearchPincode}>
            <div className="flex w-full">
              <Form.Item
                name="searchPincode"
                className="m-0 w-full"
                rules={[{ pattern: /^\d{6}$/, message: 'Please enter valid pincode.' }]}>
                <Input className="rounded-r-none" placeholder="Enter Pincode" />
              </Form.Item>

              <Button
                htmlType="submit"
                className="bg-blue-600 !text-white flex justify-center items-center rounded-l-none"
                icon={<SearchOutlined />}
              />
            </div>
          </Form>

          <Button
            className="flex items-center primary-button"
            onClick={() => setAddAddressModal(true)}
            icon={<BiPlusCircle size={18} />}>
            Add Address
          </Button>
        </div>

        <Spin spinning={modalLoading}>
          {modalLoading || addressList?.addresses?.length ? (
            <>
              <div className="flex gap-4 flex-wrap min-h-[200px] mt-5 mb-3">
                {addressList?.addresses?.map((item: IPickupAddressItem) => (
                  <AddressCard
                    key={item?.id}
                    isSelected={selectedAddress?.id === item?.id}
                    address={item}
                    handleSelectAddress={handleSelectAddress}
                  />
                ))}
              </div>

              <div className="text-right mt-6">
                <Pagination
                  responsive={true}
                  showQuickJumper={true}
                  showSizeChanger={true}
                  current={currentPage}
                  pageSize={pageLimit}
                  pageSizeOptions={[20, 30, 50]}
                  total={
                    addressList?.count ? Math.ceil(addressList?.count / pageLimit) * pageLimit : 0
                  }
                  onChange={onPageChange}
                />
              </div>
            </>
          ) : (
            <Empty className="mt-5" description={`No Address for ${enteredPincode}.`} />
          )}
        </Spin>
      </Modal>

      <AddNewAddress
        businessId={businessId}
        addAddressModal={addAddressModal}
        setAddAddressModal={setAddAddressModal}
        handleGetAllAddress={handleGetPickupAddress}
      />
    </>
  );
};

export default SelectPickupAddressModal;
