import { Button, Tag } from 'antd';
import React, { FC } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { IPickupAddressItem } from '../../../../../interfaces/b2bBulkOrder';

interface IAddressCard {
  isSelected: boolean;
  showButton?: boolean;
  address: IPickupAddressItem | null | undefined;
  handleSelectAddress?: (address: IPickupAddressItem) => void;
  length?: number | undefined;
}

const AddressCard: FC<IAddressCard> = ({
  isSelected,
  showButton = true,
  address,
  handleSelectAddress,
  length
}) => {
  return (
    <div
      key={address?.id}
      className={`py-2 px-3 border border-gray-300 ${
        length === 1 ? 'w-[100%]' : 'w-[48%] xl:w-[32%]'
      } flex flex-col justify-between rounded-lg hover:bg-gray-100 transition-all duration-4ms`}>
      <div>
        <Tag className="w-full text-center text-lg bg-gray-300 mb-2 capitalize">{address?.tag}</Tag>

        <h3 className="text-xl capitalize">{address?.name || '--'}</h3>
        {address?.companyName && (
          <p>
            <b>Company Name :- </b>
            {address?.companyName}
          </p>
        )}
        <p className="font-bold">Address :-</p>

        {address?.line1 && (
          <p>
            <b>Line 1: </b>
            {address?.line1?.trim()}
          </p>
        )}
        {address?.line2 && (
          <p>
            <b>Line 2: </b>
            {address?.line2?.trim()}
          </p>
        )}
        {address?.line3 && (
          <p>
            <b>Line 3: </b>
            {address?.line3}
          </p>
        )}
      </div>
      <div className="mt-2">
        <p>
          {address?.city && <span className="mr-1">{`${address?.city?.trim()},`}</span>}

          {address?.state && <span className="mr-1">{address?.state?.trim()},</span>}

          {address?.country && <span className="mr-1">{`${address?.country?.trim()}`}</span>}

          {address?.postalCode && <span>{`- ${address?.postalCode?.trim()}.`}</span>}
        </p>
        <div>
          {address?.mobileNo && (
            <div className="flex gap-2 items-center">
              <FaPhoneAlt className="text-blue-500" />
              <p>{address?.mobileNo}</p>
            </div>
          )}

          {address?.email && (
            <div className="flex gap-2 items-center">
              <MdEmail className="text-blue-500" />
              <p>{address?.email}</p>
            </div>
          )}
        </div>

        {showButton && (
          <div className="flex justify-end mt-4">
            {isSelected ? (
              <Button type="default" disabled>
                Selected
              </Button>
            ) : (
              <Button
                className="primary-button"
                onClick={() => address && handleSelectAddress?.(address)}>
                Select
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressCard;
