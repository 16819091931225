import React, { FC } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { FaDownload } from 'react-icons/fa';

import { IInvoiceList, IInvoiceListItem } from '../../../../interfaces/invoiceListAndDetails';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import NavigateLink from '../../../CommonComponents/NavigateLink';

interface IInvoiceListComp {
  isLoading: boolean;
  invoiceListData: IInvoiceList;
}
const InvoiceList: FC<IInvoiceListComp> = ({ isLoading, invoiceListData }) => {
  const columns: ColumnsType<IInvoiceListItem> = [
    {
      title: 'Invoice Id',
      dataIndex: 'invoiceId',
      width: 200,
      render: (id) => (id ? <NavigateLink text={id} path={`/invoicing-details/${id}`} /> : 'NA')
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      width: 200,
      render: (num) => <GetCopyTextDiv text={num} />
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 150,
      render: (dateString) => moment(dateString).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'GST No',
      dataIndex: 'gstNo',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Download Invoice',
      dataIndex: 'invoiceUrl',
      width: 120,
      fixed: 'right',
      render: (url) => (
        <Tooltip title="Download">
          <Button
            onClick={() => window.open(url, '_blank')}
            icon={<FaDownload size={25} />}
            className="border-none"
          />
        </Tooltip>
      )
    }
  ];
  return (
    <div>
      <h2 className="text-lg font-bold m-0 mb-5">Invoices List</h2>

      <Table
        scroll={{ x: 850 }}
        loading={isLoading}
        columns={columns}
        pagination={false}
        dataSource={invoiceListData?.invoiceList}
      />
    </div>
  );
};

export default InvoiceList;
