import { Button, Form, Select, Spin } from 'antd';
import React, { useState } from 'react';
import { doc, onSnapshot } from '@firebase/firestore';
import { FaDownload } from 'react-icons/fa';

import Formlabel from '../../CommonComponents/FormLabel';
import OrderIdInput from './OrderIdInput';
import BulkShipmentLabel from './BulkShipmentLabel';
import { generateBulkLabel, generateInvoice, generateLabel, generationCount } from './api';
import { IGenerateBulkShipmentLabel, IInvoiceResponse } from '../../../interfaces/generateDocs';
import { db } from '../../../firebase/firebase';
const { Option } = Select;

interface IGenerateDocForm {
  docType: string;
  orderId: string;
  orderIds: string[];
}
const ShipmentLabelAndInvoice = () => {
  const [generateDocForm] = Form.useForm();
  const [selectedDocType, setSelectedDocType] = useState<string>('');
  const [orderIdsData, setOrderIdsData] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>('No File Chosen');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [responseData, setResponseData] = useState<IInvoiceResponse | undefined>();
  const [bulkShipmentLabel, setBulkShipmentLabel] = useState<
    IGenerateBulkShipmentLabel | undefined
  >();

  const [receiptCounts, setReceiptCounts] = useState<{
    currentCount: number;
    totalCount: number;
  }>({
    currentCount: 0,
    totalCount: 0
  });

  const clearData = () => {
    setOrderIdsData([]);
    setFileName('No File Chosen');
    setResponseData(undefined);
    setBulkShipmentLabel(undefined);
  };
  const clearForm = () => {
    setSelectedDocType('');
    generateDocForm.resetFields();
    clearData();
  };

  const docType = [
    {
      value: 'invoice',
      label: 'Invoice'
    },
    {
      value: 'shipmentLabel',
      label: 'Shipment Label'
    },
    {
      value: 'bulkShipmentLabel',
      label: 'Bulk Shipment Label'
    }
  ];

  const onDocTypeChange = (option: string) => {
    setSelectedDocType(option);
    generateDocForm.resetFields(['orderId', 'orderIds']);
    clearData();
  };

  const onSubmitForm = async (values: IGenerateDocForm) => {
    if (values?.docType === 'invoice') {
      await generateInvoice({
        setLoading: setIsLoading,
        orderId: values?.orderId,
        setData: setResponseData
      });
    }
    if (values?.docType === 'shipmentLabel') {
      await generateLabel({
        setLoading: setIsLoading,
        orderId: values?.orderId,
        setData: setResponseData
      });
    }
    if (selectedDocType === 'bulkShipmentLabel') {
      setReceiptCounts({
        currentCount: 0,
        totalCount: 0
      });
      const batchId = await generationCount({
        setLoading: setIsLoading
      });

      if (batchId) {
        labelGenerationRealtimeCounts(batchId);
      } else {
        console.log('Error in generating batch id for shipment label');
      }

      await generateBulkLabel({
        setLoading: setIsLoading,
        orderIds: orderIdsData,
        batchId: batchId,
        setData: setBulkShipmentLabel
      });
    }
  };

  const labelGenerationRealtimeCounts = async (id: string) => {
    const docRef = doc(db, 'ongoingProcess', id);
    setIsLoading(true);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setReceiptCounts({
            currentCount: snapshot.data()?.currentCount,
            totalCount: snapshot.data()?.totalCount
          });
        }
      },
      (error) => {
        console.error('Error fetching Firestore document:', error);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  };

  return (
    <div>
      <h2 className="text-xl font-normal m-0">Shipment Label And Invoice</h2>
      <div className="flex flex-col items-center">
        <div className="my-5 w-[90%] md:w-[500px] primary-box-shadow rounded-xl">
          <Spin
            spinning={isLoading}
            tip={
              selectedDocType == 'bulkShipmentLabel'
                ? `Please wait, generated ${receiptCounts?.currentCount || 0} / ${
                    receiptCounts?.totalCount
                  } receipts. `
                : ''
            }>
            <div className="p-5 bg-white opacity-70 rounded-xl">
              <Form
                disabled={!!responseData || !!bulkShipmentLabel}
                onFinish={onSubmitForm}
                id="generateDocForm"
                form={generateDocForm}
                layout="vertical">
                <Form.Item
                  name="docType"
                  label={<Formlabel labelText="Document Type" />}
                  rules={[{ required: true, message: 'Please select any option' }]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="value"
                    placeholder="Select document type"
                    className="w-full"
                    onChange={(value) => onDocTypeChange(value)}>
                    {docType.map((ele, index) => (
                      <Option key={ele?.value} value={ele?.value}>
                        {ele?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {selectedDocType ? (
                  ['shipmentLabel', 'invoice']?.includes(selectedDocType) ? (
                    <OrderIdInput />
                  ) : (
                    <BulkShipmentLabel
                      fileName={fileName}
                      setFileName={setFileName}
                      form={generateDocForm}
                      fileData={orderIdsData}
                      setFileData={setOrderIdsData}
                      setBulkShipmentLabel={setBulkShipmentLabel}
                    />
                  )
                ) : null}
              </Form>

              {responseData || bulkShipmentLabel ? (
                <div className="mt-5 flex gap-5 justify-center">
                  <Button
                    icon={<FaDownload />}
                    href={
                      bulkShipmentLabel?.shipmentLabelUrl
                        ? bulkShipmentLabel?.shipmentLabelUrl
                        : responseData?.presignedUrl
                    }
                    target="_blank"
                    className="primary-button">
                    Download
                  </Button>

                  <Button type="default" onClick={clearForm}>
                    Clear Form
                  </Button>
                </div>
              ) : (
                <div className="pt-10 flex justify-center">
                  <Button
                    disabled={!selectedDocType || !!responseData}
                    className="primary-button"
                    htmlType="submit"
                    form="generateDocForm">
                    Generate
                  </Button>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ShipmentLabelAndInvoice;
