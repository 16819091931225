import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import {
  FaChartArea,
  FaShoppingBag,
  FaWallet,
  FaRegUserCircle,
  FaBusinessTime,
  FaTable,
  FaFileDownload,
  FaMailBulk,
  FaTicketAlt,
  FaSearchLocation,
  FaFileInvoice,
  FaListOl,
  FaLocationArrow
} from 'react-icons/fa';
import { BsClipboardDataFill, BsBuildingsFill } from 'react-icons/bs';
import { HiReceiptRefund, HiDocumentReport, HiOutlineRefresh } from 'react-icons/hi';
import {
  MdBusiness,
  MdDifference,
  MdDisplaySettings,
  MdEmojiTransportation,
  MdManageHistory,
  MdMap,
  MdOutlineAddBusiness,
  MdOutlineHistoryToggleOff,
  MdOutlineManageSearch,
  MdOutlineSecurity,
  MdOutlineSmsFailed,
  MdVerifiedUser
} from 'react-icons/md';
import Logo from '../../assets/c-logo.svg';
import { Link } from 'react-router-dom';
import UserProfile from '../Sidebar/UserProfile';
import { useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { B2B_AGREEMENT_LINK, IS_REFUND_ENABLED } from '../../utils/config';
import { IDecodedToken } from '../../interfaces/decodedToken';
import UserMenu from '../pages/ManageMenu/api';
import { BiGroup } from 'react-icons/bi';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 1024);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const location = useLocation();

  const selectedKeys = location?.pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const { error, isLoading, getMenuMappedData, getMappedMenu } = UserMenu();
  const [menuIds, setMenuIds] = useState<string[]>([]);

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type
    } as MenuItem;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwt_decode(token) as IDecodedToken;

      if (decoded?.role?.length) {
        getMenuMappedData({ department: decoded.department, role: decoded.role[0] });
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const userMenuList = getMappedMenu.MappedList;
    const ids: string[] = userMenuList.map((item: { menuId: string }) => item.menuId);
    setMenuIds(ids);
  }, [getMappedMenu]);

  const menuItems: MenuItem[] = [
    getItem(<Link to="/dashboard">Coreyo Dashboard</Link>, 'dashboard', <FaChartArea size={20} />),
    getItem('Orders', 'orders-main', <FaShoppingBag size={20} />, [
      getItem(<Link to="/orders">Orders List</Link>, 'orders', <FaTable size={20} />),
      getItem(
        <Link to="/bulk-orders-list" title="Bulk Orders List">
          Bulk Orders List
        </Link>,
        'bulk-orders-list',
        <FaMailBulk size={20} />
      ),
      getItem(
        <Link to="/business-orders-list" title="Business Orders List">
          Business Orders List
        </Link>,
        'business-orders-list',
        <FaBusinessTime size={20} />
      ),
      getItem(
        <Link to="/bulk-orders">Book Custom Bulk</Link>,
        'bulk-orders',
        <BsBuildingsFill size={20} />
      ),
      getItem(
        <Link to="/push-failed-order">Push Failed Order</Link>,
        'push-failed-order',
        <MdOutlineSmsFailed size={20} />
      ),
      getItem(
        <Link to="/shipment-label-download" title="Download Shipment Label">
          Shipment Label
        </Link>,
        'shipment-label-download',
        <FaFileDownload size={20} />
      )
    ]),
    getItem(
      <Link to="/invoicing-engine" title="Invoicing Engine">
        Invoicing Engine
      </Link>,
      'invoicing-engine',
      <FaFileInvoice size={20} />
    ),
    // getItem('ECBO', 'ecbo-main', <MdOutlineAddBusiness size={20} />, [
    // getItem(
    //   <Link to="/ecbo-bulk-orders">ECBO Bulk Book</Link>,
    //   'ecbo-bulk-orders',
    //   <BsBuildingsFill size={20} />
    // ),
    getItem(<Link to="/ecbo-batches">ECBO</Link>, 'ecbo-batches', <BiGroup size={20} />),
    // ]),

    getItem(
      <Link to="/shipment-label-and-invoice" title="Shipment Label & Invoice">
        Shipment Label & Invoice
      </Link>,
      'shipment-label-and-invoice',
      <FaFileInvoice size={20} />
    ),
    getItem(
      <Link to="/update-orders">Update Orders</Link>,
      'update-orders',
      <HiOutlineRefresh size={20} />
    ),
    getItem('B2B Registration', 'business-main', <MdOutlineAddBusiness size={20} />, [
      getItem(
        <Link to="/b2b-onboarding" title="B2B Onboarding">
          B2B Onboarding
        </Link>,
        'b2b-onboarding',
        <MdBusiness size={20} />
      ),
      getItem(
        <Link to="/b2b-company-list" title="B2B Verification">
          B2B Verification
        </Link>,
        'b2b-company-list',
        <MdOutlineSecurity size={20} />
      ),
      B2B_AGREEMENT_LINK
        ? getItem(
            <Link to={B2B_AGREEMENT_LINK} target="_blank" title="Download Service Agreement">
              Service Agreement
            </Link>,
            'downloadServiceAgreement',
            <FaFileDownload size={20} />
          )
        : null
    ]),
    getItem('B2B Orders', 'business-v3-orders-main', <MdOutlineAddBusiness size={20} />, [
      getItem(
        <Link to="/b2b-orders-list" title="B2B Orders List">
          Orders List
        </Link>,
        'b2b-orders-list',
        <FaListOl size={20} />
      ),
      getItem(
        <Link to="/b2b-bulk-order" title="Book Bulk Order">
          Book Bulk Order
        </Link>,
        'b2b-bulk-order',
        <HiDocumentReport size={20} />
      ),
      getItem(
        <Link to="/b2b-bulk-orders-list" title="B2B Bulk Order">
          Bulk Orders list
        </Link>,
        'b2b-bulk-orders-list',
        <HiDocumentReport size={20} />
      ),
      getItem(
        <Link to="/b2b-places-details" title="Search Places">
          Search Places
        </Link>,
        'b2b-places-details',
        <MdMap size={20} />
      ),
      getItem(
        <Link to="/b2b-address-book" title="Address Book" target="_blank">
          Address Book
        </Link>,
        'b2b-address-book',
        <FaLocationArrow size={20} />
      )
    ]),
    getItem(<Link to="/wallet">Wallet</Link>, 'wallet', <FaWallet size={20} />),
    getItem(<Link to="/users">Users</Link>, 'users', <FaRegUserCircle size={20} />),
    IS_REFUND_ENABLED
      ? getItem(
          <Link to="/refunded-orders">Refunded Orders</Link>,
          'refunded-orders',
          <HiReceiptRefund size={20} />
        )
      : null,
    getItem(
      <Link to="/seller-verification">Seller Verification</Link>,
      'seller-verification',
      <MdVerifiedUser size={20} />
    ),
    getItem('Reports', 'report-main', <HiDocumentReport size={20} />, [
      getItem(
        <Link to="/orders-report">Orders Report</Link>,
        'orders-report',
        <HiDocumentReport size={20} />
      ),
      getItem(
        <Link to="/business-orders-report" title="Business Orders Report">
          Business Orders Report
        </Link>,
        'business-orders-report',
        <HiDocumentReport size={20} />
      ),
      getItem(
        <Link to="/bulk-order-report" title="Bulk Orders Report">
          Bulk Orders Report
        </Link>,
        'bulk-order-report',
        <HiDocumentReport size={20} />
      ),
      getItem(
        <Link to="/wallet-report">Wallet Report</Link>,
        'wallet-report',
        <BsClipboardDataFill size={20} />
      )
    ]),
    getItem(
      <Link to="/manage-providers">Manage Providers</Link>,
      'manage-providers',
      <MdEmojiTransportation size={20} />
    ),
    getItem('Manage Pincodes', 'pincodes-main', <MdManageHistory size={20} />, [
      getItem(
        <Link title="Check Pincodes" to="/pincode-availability">
          Check Pincodes
        </Link>,
        'pincode-availability',
        <FaSearchLocation size={20} />
      ),
      getItem(
        <Link title="Handle Pincodes" to="/handle-pincodes">
          Handle Pincodes
        </Link>,
        'handle-pincodes',
        <MdOutlineManageSearch size={20} />
      ),
      getItem(
        <Link title="Pincodes Logs" to="/pincodes-logs">
          Pincodes Logs
        </Link>,
        'pincodes-logs',
        <MdOutlineHistoryToggleOff size={20} />
      )
    ]),

    getItem(
      <Link to="/update-ticker">Update Ticker</Link>,
      'update-ticker',
      <FaTicketAlt size={20} />
    ),
    getItem(
      <Link to="/manage-menu">Manage Menu</Link>,
      'manage-menu',
      <MdDisplaySettings size={20} />
    ),

    getItem(<Link to="/add-menu">Add Menu Items</Link>, 'add-menu', <MdDifference size={20} />),
   
    getItem('Fintech Orders', 'fintech-orders', <FaShoppingBag size={20} />, [
      getItem(
        <Link to="/upload-BookingReq">Upload Booking</Link>,
        '/upload-BookingReq',
        <FaMailBulk size={20} />
      ),
      getItem(
        <Link to="/booking-request">Booking Request</Link>,
        'booking-request',
        <FaTable size={20} />
      ),
      getItem(
        <Link to="/fintech/bulk-order-list" title="Bulk Orders List">
          Bulk Orders List
        </Link>,
        '/fintech/bulk-order-list',
        <FaMailBulk size={20} />
      ),
      getItem(
        <Link to="/upload-acknowledgement">Upload Acknowledgement</Link>,
        '/upload-acknowledgement',
        <FaMailBulk size={20} />
      ),
      getItem(<Link to="/fixed-charges">Charges</Link>, '/fixed-charges', <FaMailBulk size={20} />)
    ]),

    getItem('Fintech ', 'fintech', <FaShoppingBag size={20} />, [
      getItem(
        <Link to="/user-finx">User</Link>,
        '/user-finx',
        <FaRegUserCircle size={20} />
      ),
      getItem(
        <Link to="/order-finx">Order</Link>,
        '/order-finx',
        <FaShoppingBag size={20} />
      ),
      getItem(
        <Link to="/upload-acknowledgement-finx">Upload Acknowledgement</Link>,
        '/upload-acknowledgement-finx',
        <FaMailBulk size={20} />
      )
    ]),

    getItem('Manual Tracking', 'manual-tracking', <FaShoppingBag size={20} />, [
      getItem(
        <Link to="/download-tracking">Active Orders</Link>,
        '/download-tracking',
        <FaTable size={20} />
      ),
      getItem(
        <Link to="/upload-tracking">Upload Tracking</Link>,
        '/upload-tracking',
        <FaMailBulk size={20} />
      ),
      getItem(<Link to="/tracking">Tracking</Link>, '/tracking', <FaMailBulk size={20} />)
    ]),
    getItem(<Link to="/discrepancy">Discrepancy</Link>, 'discrepancy', <MdDifference size={20} />)
  ];

  const filteredMenuItems = menuItems?.filter((item: any) => {
    return item?.key !== null && menuIds.includes(item?.key);
  });
  const rootSubmenuKeys = [
    'orders-main',
    'report-main',
    'pincodes-main',
    'fintech-orders',
    'fintech',
    'manual-tracking',
    'business-main',
    'business-v3-orders-main',
    'ecbo-main'
  ]; // add sub menu main keys

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // eslint-disable-next-line
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onMenuItemSelect: MenuProps['onSelect'] = ({ keyPath }) => {
    const latestOpenKey = keyPath?.pop() as string;
    if (!rootSubmenuKeys?.includes(latestOpenKey)) {
      setOpenKeys([]);
    }
  };
  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={225}>
      <Header
        style={{
          padding: '10px 10px 10px 15px',
          background: '#002447',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Button
          type="text"
          icon={
            collapsed ? (
              <MenuUnfoldOutlined style={{ color: 'white' }} />
            ) : (
              <MenuFoldOutlined style={{ color: 'white' }} />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 40,
            height: 40,
            position: 'absolute',
            left: 20
          }}
        />
        {!collapsed && <img src={Logo} alt="Logo" width={100} height={30} />}
      </Header>

      <UserProfile collapsed={collapsed} />

      <Menu
        theme="dark"
        mode="inline"
        className="sidebar-height !bg-none"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onSelect={onMenuItemSelect}
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        style={{ background: '#003366', overflowY: 'scroll', scrollbarWidth: 'none' }}
        // items={menuItems}
        items={filteredMenuItems}
      />
    </Sider>
  );
};
export default Sidebar;
