import { Modal, Table } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { IClientDetailsApiResponse, ITaxInfo } from '../../../../interfaces/invoicingEngine';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdOutlineDangerous } from 'react-icons/md';

interface ITaxInfoListModal {
  taxInfoListModal: boolean;
  setTaxInfoListModal: Dispatch<SetStateAction<boolean>>;
  detailsData: IClientDetailsApiResponse | null;
}
const TaxInfoListModal: FC<ITaxInfoListModal> = ({
  taxInfoListModal,
  setTaxInfoListModal,
  detailsData
}) => {
  const onClose = () => {
    setTaxInfoListModal(false);
  };

  const columns: ColumnsType<ITaxInfo> = [
    {
      title: 'Tax Registration No',
      dataIndex: 'taxRegistrationNo',
      width: 200,
      render: (num) => <GetCopyTextDiv text={num} />
    },
    {
      title: 'Legal Name',
      dataIndex: 'legalName',
      width: 150,
      render: (name) => <GetCopyTextDiv text={name} />
    },
    {
      title: 'Place Of Supply',
      dataIndex: 'placeOfSupply',
      width: 200,
      render: (name) => <GetCopyTextDiv text={name} />
    },
    {
      title: 'Is Primary',
      dataIndex: 'isPrimary',
      width: 100,
      render: (primary) =>
        primary ? (
          <FaRegCheckCircle color="green" size={20} />
        ) : (
          <MdOutlineDangerous color="red" size={25} />
        )
    }
  ];
  return (
    <Modal
      width={800}
      onCancel={onClose}
      open={taxInfoListModal}
      footer={false}
      title="Tax Info List">
      <Table
        scroll={{ x: 650 }}
        columns={columns}
        dataSource={detailsData?.zohoDetails?.taxInfoList}
      />
    </Modal>
  );
};

export default TaxInfoListModal;
