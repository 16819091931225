import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserDetails,changeUserstatus } from '../api';

const FintechUser: React.FC = () => {
  const [detailsData, setDetailsData] = useState<{ title: string; value: any }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const state = useLocation()?.state;
  const userFromState = state?.Fintechuser;
 

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        let user = userFromState;

        if (!user && userId) {
          const response = await getUserDetails(userId);
          user = response.userDetail;
          const business = response.businessDetail;

          const mappedData = [
            {
              title: 'User Id',
              value: userId || '--' 
            },
            { 
              title: 'First Name',
              value: user?.firstName || '--' 
            },
            { 
              title: 'Last Name',
              value: user?.lastName || '--' 
            },
            { 
              title: 'Email',
              value: user?.email || '--'
            },
            { 
              title: 'Mobile', 
              value: user?.mobile || '--' 
            },
            { 
              title: 'ISD Code', 
              value: user?.isdCode || '--' 
            },
            {
              title: 'UserStatus',
              value: (
                <>
                  {user?.status === 'PENDING' ? (
                    <Button
                      className="primary-button"
                      onClick={async () => {
                          const updatedStatus = await changeUserstatus({
                            userId:userId,
                            status:"ENABLED"
                        });
                          console.log(updatedStatus,60);
                       }}>
                      Verify
                    </Button>
                  ) : (
                    user?.status || '--'
                  )}
                </>
              ),
            },            
            {
              title: 'Created At',
              value: user?.createdAt
                ? new Date(user.createdAt).toLocaleDateString()
                : '--',
            },
            {
              title: 'Updated At',
              value: user?.updatedAt
                ? new Date(user.updatedAt).toLocaleDateString()
                : '--',
            },
            { 
              title: 'Business ID', 
              value: business?.businessId || '--' 
            },
            { 
              title: 'Brand Name', 
              value: business?.brandName || '--'
            },
            { 
              title: 'Sector', 
              value: business?.sector || '--' 
            },
            { 
              title: 'Website', 
              value: business?.website || '--' 
            },
            { 
              title: 'KycStatus', 
              value: business?.kycStatus || '--' 
            },
            {
              title: 'BusinessStatus',
              value: business?.status || '--'
            },
            {
              title: 'Created At',
              value: business?.createdAt
                ? new Date(business.createdAt).toLocaleDateString()
                : '--',
            },
            {
              title: 'Updated At',
              value: business?.updatedAt
                ? new Date(business.updatedAt).toLocaleDateString()
                : '--',
            },
          ];

          setDetailsData(mappedData);
        } 
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [userId, userFromState]);

  return (
    <>
      <Button  type="link" onClick={() => navigate('/userlist-finx')}>
        {`<`}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="font-bold text-3xl mt-8">User Information</div>
      <div className="bg-white flex mt-20 ">
        <div className="min-h-[500px]">
          <div className="px-4 flex flex-col gap-3 my-5 ">
            {loading ? (
                <div>Loading...</div>
              ) : (
                detailsData.map((data) => (
                  <div key={data.title} className="flex gap-3 text-xl">
                    <p className="w-[250px] flex justify-between font-bold">
                      {data.title} <span>:</span>
                    </p>
                    <p className="capitalize">{data.value}</p>
                  </div>
                ))
              )}
          </div>
        </div>
       
      </div>
    </>
  );
};

export default FintechUser;

