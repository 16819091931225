import React, { useEffect, useState } from "react";
import { Button, Spin, Steps, Select, notification, Card } from "antd";
import { useNavigate, useParams, useLocation, Form } from "react-router-dom";
import { generateBulk, getUserDetails, pickupAddressList } from "../api";

const GenerateBulk: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [pickupAddressData, setPickupAddressData] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [selectedServiceType, setSelectedServiceType] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
   const [selectAll, setSelectAll] = useState(false);
  const location = useLocation();
  const [orderCount, setOrderCount] = useState<number>(0);
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(30);


  useEffect(() => {
    if (location.state && location.state.orderCount !== undefined) {
      setOrderCount(location.state.orderCount);
    }
    if (location.state && location.state.selectedOrderIds) {
      setSelectedOrderIds(location.state.selectedOrderIds);
      setSelectAll(location.state.selectAll);
    }
  }, [location.state]); 

  useEffect(() => {
    const getAddress = async () => {
      try {
        setIsLoading(true);
        const pickupAddressListApiRes = await pickupAddressList({ userId, pageNumber, pageLimit });
        const list = pickupAddressListApiRes.list;
        const dropDownInterface = list.map((address: any) => ({
          label: (
            <div>
              <strong>{address.name}</strong> <br />
              <span>{address.email}</span> <br />
              <span>{address.mobile}</span>
              <span className="ms-2"></span>
              <br />
              <span>{address.line1}, {address.line2}, {address.line3}, {address.pincode}</span><br />
              <span>{address.city}, {address.state}, {address.country}</span>
            </div>
          ),
          value: address.addressId,
        }));
        setPickupAddressData(dropDownInterface);
      } catch (error) {
        notification.error({ message: "Error fetching address data." });
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      getAddress();
    }
  }, [userId, pageNumber, pageLimit]);

  const serviceTypeOptions: { label: string; value: string }[] = [
    { label: 'SELF_DROP', value: 'SELF_DROP' },
    { label: 'REGISTERED_POST', value: 'REGISTERED_POST' },
    { label: 'RPAD', value: 'RPAD' },
    { label: 'ORDINARY_POST', value: 'ORDINARY_POST' },
  ];

  const providerOptions: { label: string; value: string }[] = [
    { label: 'INDIA_POST', value: 'INDIA_POST' },
  ];

  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        console.log("User Details:", response);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const handleGenerateBulk = async () => {
    if (!selectedAddress || !selectedServiceType || !selectedProvider) {
      notification.error({ message: "Please complete all steps before generating bulk." });
      return;
    }

    try {
      setIsLoading(true);

      const payload = {
        userId,
        orderIds: selectedOrderIds,
        addressId: selectedAddress.value,
        serviceType: selectedServiceType,
        serviceProvider: selectedProvider,
        selectAll
      };
      console.log("Generated payload:", payload);
      await generateBulk(payload);
      if (payload.serviceProvider !== "INDIA_POST") {
        throw new Error("Invalid service provider selected.");
      }
      notification.success({ message: "Bulk generated successfully!" });
      navigate("/userlist-finx");
    } catch (error) {
      notification.error({ message: "Failed to generate bulk orders." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button type="link" onClick={() => navigate(`/booking-request-finx/${userId}`)}>{"< Back to Booking Requests"}</Button>
      <h2 className="text-xl font-normal m-5">
        Generate Bulk for:{" "}
        {userData && userData.firstName ? (
          `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
        ) : (
          "Loading..."
        )}
      </h2>
      <div className="w-full p-8 bg-white shadow-md rounded-xl">
        <Steps current={step} className="mb-8">
          <Steps.Step title="Order Count" />
          <Steps.Step title="Pickup Address" />
          <Steps.Step title="Service Type & Service Provider" />
          <Steps.Step title="Generate Bulk" />
        </Steps>
        <Spin spinning={isLoading}>
        <div>
            {step === 0 && (
              <div className="text-center">
                 <div className="mb-4">
                  {location.state?.orderCount > 0 ? (
                    <p className="text-lg font-semibold">
                      {location.state.orderCount === location.state.totalOrders ? (
                        <>
                          All orders are selected<br/>
                          Order Limit is 5000<br/>
                          Total orders: <strong>{location.state.totalOrders}</strong>
                        </> ) : (
                        <>
                          Selected orders: <strong>{location.state.orderCount}</strong>
                        </>
                      )}
                    </p>
                  ) : (
                    <p className="text-red-500">No orders selected. Please go back and select orders.</p>
                  )}
                </div>

                <Button
                  type="primary"
                  onClick={() => setStep(1)}
                  disabled={location.state?.orderCount === 0}>
                  Next
                </Button>

              </div>
            )}
            {step === 1 && (
              <div>
                <strong className="">Select a Pickup Address</strong>
                {pickupAddressData && pickupAddressData.length > 0 ? (
                  <div className="flex flex-wrap gap-4 my-4">
                    {pickupAddressData.map((address) => (
                      <Card
                        key={address.value}
                        style={{ width: 500, position: "relative", cursor: "pointer" }}
                        hoverable
                        className={selectedAddress?.value === address.value ? "border-2 border-blue-500" : ""}
                        onClick={() => setSelectedAddress(address)}>
                        <div style={{ position: "absolute", top: 10, right: 10 }}>
                          <input
                            type="checkbox"
                            checked={selectedAddress?.value === address.value}
                            onChange={() => setSelectedAddress(address)}
                            className="accent-blue-500"
                          />
                        </div>
                        <p>{address.label}</p>
                        <hr className="my-5" />
                      </Card>
                    ))}
                  </div>
                ) : (
                  <p className="text-red-500 m-5">Pickup address not found</p>
                )}
                <div className="flex justify-between">
                  <Button
                    type="primary"
                    onClick={() => setStep(2)}
                    disabled={!selectedAddress}>
                    Next
                  </Button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="flex justify-center items-center">
                <div className="w-full max-w-md text-center">
                  <div className="mb-4">
                    <Select
                      placeholder="Select Provider"
                      style={{ width: "200px" }}
                      value={selectedProvider}
                      onChange={(value) => setSelectedProvider(value)}
                      options={providerOptions}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      placeholder="Select Service Type"
                      style={{ width: "200px" }}
                      value={selectedServiceType}
                      onChange={(value) => setSelectedServiceType(value)}
                      options={serviceTypeOptions}
                    />
                  </div>
                  <div className="flex justify-center">
                    <Button
                      type="primary"
                      onClick={() => setStep(3)}
                      disabled={!selectedServiceType || !selectedProvider}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div>
                <div className="flex justify-center">
                  <Button
                    type="default"
                    onClick={() => setStep(2)}
                    className="me-3"
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleGenerateBulk();
                      navigate(`/booking-request-finx/${userId}`);
                    }}
                    disabled={!selectedServiceType || !selectedProvider}
                  >
                    Generate Bulk
                  </Button>

                </div>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default GenerateBulk;
