import { Button, Divider, Form, Input, Modal, Select, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { addNewClientFormValidations, CLIENT_TYPES_OPTIONS } from '../constants';
import { getStatesList, getCountriesList } from '../../../../utils/addressApis';
import { FaPlusCircle } from 'react-icons/fa';
import { IStateList, ICountry } from '../../../../interfaces/addressApis';
import { IAddClientPayload, IVerifyUserForm } from '../../../../interfaces/invoicingEngine';
import { addNewClient } from '../api';
import VerifyUser from '../VerifyUser';

interface IAddNewClientFormProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  handleGetClientsList: () => void;
}

const AddNewClientForm: FC<IAddNewClientFormProps> = ({
  isModalOpen,
  setIsModalOpen,
  handleGetClientsList
}) => {
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [registerClientForm] = Form.useForm();
  const [verifyUserForm] = Form.useForm<IVerifyUserForm>();

  const [userId, setUserId] = useState<string>('');

  const handleCountryList = async () => {
    await getCountriesList({ setData: setCountriesList });
  };

  useEffect(() => {
    if (isModalOpen) {
      handleCountryList();
    }
  }, [isModalOpen]);

  const onCountryChange = async (countryCode: string) => {
    setStatesData([]);
    registerClientForm.resetFields(['state']);
    const countryDetails = JSON.parse(countryCode);

    await getStatesList({
      setData: setStatesData,
      countryCode: countryDetails?.countryCode,
      setLoading: setModalLoading
    });
  };

  const onSubmit = async (values: IAddClientPayload) => {
    const countryDetails = JSON.parse(values?.billingAddress?.country);
    const payload = {
      ...values,
      userId: userId,
      billingAddress: {
        ...values?.billingAddress,
        ...JSON.parse(values?.billingAddress?.state),
        country: countryDetails?.country
      }
    };
    await addNewClient({ setLoading: setModalLoading, payload, callBack: onAddSuccess });
  };

  const onAddSuccess = () => {
    onClose();
    handleGetClientsList();
  };

  const onClose = () => {
    setIsModalOpen(false);
    registerClientForm.resetFields();
    verifyUserForm.resetFields();
    setTimeout(() => {
      setUserId('');
    }, 500);
  };

  return (
    <Modal
      width={800}
      title={
        <div className="flex gap-2 items-center">
          <FaPlusCircle /> Add Client
        </div>
      }
      onCancel={onClose}
      open={isModalOpen}
      footer={false}>
      <Spin spinning={modalLoading}>
        <VerifyUser userId={userId} setUserId={setUserId} formInstance={verifyUserForm} />
        {!!userId && (
          <Form
            scrollToFirstError
            form={registerClientForm}
            id="registerClientForm"
            onFinish={onSubmit}>
            <div className="w-full pt-2">
              <Formlabel labelText="Company Name" required />
              <Form.Item
                className="mt-2"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: addNewClientFormValidations?.companyName?.required
                  }
                ]}>
                <Input type="text" placeholder="Enter company name" />
              </Form.Item>
            </div>

            <div>
              <Formlabel labelText="Website" />
              <Form.Item
                className="mt-2"
                name="website"
                rules={[
                  {
                    pattern: addNewClientFormValidations?.website?.patternValueRegex,
                    message: addNewClientFormValidations?.website?.patternMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter website" />
              </Form.Item>
            </div>

            <div>
              <Formlabel labelText="GST Number" required />
              <Form.Item
                className="mt-2"
                name="gstNumber"
                rules={[
                  { required: true, message: addNewClientFormValidations?.gstNumber?.required },
                  {
                    pattern: addNewClientFormValidations?.gstNumber?.patternValueRegex,
                    message: addNewClientFormValidations?.gstNumber?.patternMessage
                  }
                ]}>
                <Input placeholder="Enter gst number" />
              </Form.Item>
            </div>

            <div>
              <Formlabel labelText="Client Type" required />
              <Form.Item
                className="mt-2"
                name="clientType"
                rules={[
                  { required: true, message: addNewClientFormValidations?.clientType?.required }
                ]}>
                <Select
                  placeholder="Select client type"
                  options={CLIENT_TYPES_OPTIONS?.map((item) => ({ label: item, value: item }))}
                />
              </Form.Item>
            </div>

            <Divider />
            <Formlabel labelText="Billing Details :" />

            <div className="w-full mt-2">
              <Formlabel labelText="Name" required />
              <Form.Item
                className="mt-2"
                name={['billingAddress', 'name']}
                rules={[
                  { required: true, message: addNewClientFormValidations?.name?.required },
                  {
                    max: addNewClientFormValidations?.name?.maxCharactersLength,
                    message: addNewClientFormValidations?.name?.maxCharactersMessage
                  }
                ]}>
                <Input placeholder="Enter name" />
              </Form.Item>
            </div>

            <div className="w-full">
              <Formlabel labelText="Address Line 1" required />
              <Form.Item
                className="mt-2"
                rules={[
                  { required: true, message: addNewClientFormValidations?.address?.required },
                  {
                    max: addNewClientFormValidations?.address?.maxCharactersLength,
                    message: addNewClientFormValidations?.address?.maxCharactersMessage
                  },
                  {
                    pattern: addNewClientFormValidations?.address?.patternValueRegex,
                    message: addNewClientFormValidations?.address?.patternMessage
                  }
                ]}
                name={['billingAddress', 'line1']}>
                <Input placeholder="Enter address" />
              </Form.Item>
            </div>

            <div className="w-full">
              <Formlabel labelText="Address Line 2" required />
              <Form.Item
                className="mt-2"
                rules={[
                  { required: true, message: addNewClientFormValidations?.address?.required },
                  {
                    max: addNewClientFormValidations?.address?.maxCharactersLength,
                    message: addNewClientFormValidations?.address?.maxCharactersMessage
                  },
                  {
                    pattern: addNewClientFormValidations?.address?.patternValueRegex,
                    message: addNewClientFormValidations?.address?.patternMessage
                  }
                ]}
                name={['billingAddress', 'line2']}>
                <Input placeholder="Enter address" />
              </Form.Item>
            </div>

            <div className="w-full">
              <Formlabel labelText="Country" required />
              <Form.Item
                name={['billingAddress', 'country']}
                rules={[
                  { required: true, message: addNewClientFormValidations?.country?.required }
                ]}>
                <Select
                  onChange={onCountryChange}
                  showSearch
                  options={countriesList?.map((item) => ({
                    label: `${item?.name[0]} - ${item?.codes?.iso2}`,
                    value: JSON.stringify({
                      country: `${item?.name}`,
                      countryCode: item?.codes?.iso2
                    })
                  }))}
                  optionFilterProp="label"
                  placeholder="Select any country"
                />
              </Form.Item>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <Formlabel labelText="State" required />
                <Form.Item
                  className="mt-2"
                  name={['billingAddress', 'state']}
                  rules={[
                    {
                      required: true,
                      message: addNewClientFormValidations?.stateFieldName?.required
                    }
                  ]}>
                  <Select
                    showSearch
                    disabled={!statesData?.length}
                    placeholder="Select any state"
                    optionFilterProp="label"
                    options={statesData?.map((item) => ({
                      label: `${item?.name} - ${item?.code}`,
                      value: JSON.stringify({ state: `${item?.name}`, stateCode: item?.code })
                    }))}
                  />
                </Form.Item>
              </div>

              <div className="w-full md:w-1/2">
                <Formlabel labelText="City" required />
                <Form.Item
                  className="mt-2"
                  rules={[
                    {
                      required: true,
                      message: addNewClientFormValidations?.cityFieldName?.required
                    }
                  ]}
                  name={['billingAddress', 'city']}>
                  <Input placeholder="Enter city" />
                </Form.Item>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <Formlabel labelText="Postal Code" required />
                <Form.Item
                  className="mt-2"
                  rules={[
                    { required: true, message: addNewClientFormValidations?.postalCode?.required },
                    {
                      pattern: addNewClientFormValidations?.postalCode?.patternValueRegex,
                      message: addNewClientFormValidations?.postalCode?.patternMessage
                    }
                  ]}
                  name={['billingAddress', 'postalCode']}>
                  <Input placeholder="Enter postal code" />
                </Form.Item>
              </div>

              <div className="w-full md:w-1/2">
                <Formlabel labelText="Mobile Number" required />
                <Form.Item
                  className="mt-2"
                  rules={[
                    {
                      required: true,
                      message: addNewClientFormValidations?.mobileNumber?.required
                    },
                    {
                      pattern: addNewClientFormValidations?.mobileNumber?.patternValueRegex,
                      message: addNewClientFormValidations?.mobileNumber?.patternMessage
                    }
                  ]}
                  name={['billingAddress', 'contactNo']}>
                  <Input
                    type="number"
                    onKeyDown={(e) =>
                      ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    placeholder="Enter number"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex justify-center my-5">
              <Button
                className="w-[200px] font-semibold tracking-wide"
                type="primary"
                loading={modalLoading}
                htmlType="submit"
                form="registerClientForm">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default AddNewClientForm;
