import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Spin, StepProps, Steps } from 'antd';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import { APP_TOKEN } from '../../../../../utils/config';
import useOrderListDetails from '../../api';
import GetStatus from '../../../../../utils/GetStatus';
import ReactMarkdown from 'react-markdown';

interface RefundActionsProps {
  refundActionModal: boolean;
  setRefundActionModal: Dispatch<SetStateAction<boolean>>;
  refundData: any;
  showActionButtons?: boolean;
  callBackFunction?: () => void;
  isExecuteOnly?: boolean;
  onClose?: () => void;
}

const { TextArea } = Input;

const RefundActions: React.FC<RefundActionsProps> = ({
  refundActionModal,
  setRefundActionModal,
  refundData,
  showActionButtons = true,
  callBackFunction,
  isExecuteOnly,
  onClose
}) => {
  const [actionForm] = Form.useForm();
  const { tokenData } = getTokenDetails();
  const { declineRefund, approveRefund, isLoading, executeRefundedOrder } = useOrderListDetails();

  const [refundModalData, setRefundModalData] = useState<{ label: string; value: string }[]>([]);
  const [actionType, setActionType] = useState<{ approve: boolean; decline: boolean }>({
    approve: false,
    decline: false
  });

  const [refundJourney, setRefundJourney] = useState<StepProps[] | undefined>();
  const [selectedReason, setSelectedReason] = useState<{ status: string; reason: string }>({
    status: '',
    reason: ''
  });
  const [showReasonModal, setShowReasonModal] = useState<boolean>(false);

  const onClearAll = () => {
    setRefundActionModal(false);
    actionForm?.resetFields();
    setTimeout(() => {
      if (onClose) onClose();
    }, 1000);
  };

  const onClickSeeMore = (reason: string, status: string) => {
    const lines = reason.replace(/\n/g, '  \n');

    setSelectedReason({ status: status, reason: lines });
    setRefundActionModal(false);
    setShowReasonModal(true);
  };

  const onCloseReasonModal = () => {
    setShowReasonModal(false);
    setRefundActionModal(true);

    setTimeout(() => setSelectedReason({ status: '', reason: '' }), 100);
  };

  useEffect(() => {
    const refundDetails = [
      {
        label: 'Order Id',
        value: refundData?.orderId
      },
      {
        label: 'Employee',
        value: refundData?.refundObject?.length
          ? refundData?.refundObject[0]?.actionBy?.toUpperCase()
          : '--'
      },
      {
        label: 'Refund speed',
        value: refundData?.refundSpeed?.toUpperCase()
      },
      {
        label: 'Amount',
        value: refundData?.amount
      },
      {
        label: 'Refund Status',
        value: (
          <GetStatus
            currStatus={refundData?.refundStatus}
            PROCESSING="initiated"
            SUCCESS="approved"
            FAILED="declined"
            COMPLETED="refunded"
            CUSTOM_LABEL={refundData?.refundStatus?.toUpperCase()}
          />
        )
      }
    ];

    const journey = refundData?.refundObject?.map((obj: any) => ({
      title: (
        <p className="font-bold">
          <GetStatus
            currStatus={obj?.action}
            PROCESSING="requested"
            SUCCESS="approved"
            FAILED="declined"
            COMPLETED="executed"
            CUSTOM_LABEL={obj?.action?.toUpperCase()}
          />
        </p>
      ),
      description: (
        <>
          <div className="flex">
            <p className="mr-2 min-w-[80px] flex justify-between capitalize font-bold">
              Action By <span>:</span>
            </p>
            <p className="w-75">{obj?.actionBy}</p>
          </div>

          <div className="flex">
            <p className="mr-2 min-w-[80px] flex justify-between capitalize font-bold">
              Date <span>:</span>
            </p>
            <p className="w-75">{obj?.createdAt}</p>
          </div>

          <div className="flex">
            <p className="mr-2 min-w-[80px] flex justify-between capitalize font-bold">
              Reason <span>:</span>
            </p>
            {obj?.reasonOfAction?.length > 20 ? (
              <div className="flex gap-1">
                <p>{`${obj?.reasonOfAction?.slice(0, 20)}...`}</p>
                <button
                  className="text-blue-500 font-bold"
                  onClick={() => onClickSeeMore(obj?.reasonOfAction, obj?.action)}>
                  Show More
                </button>
              </div>
            ) : (
              <p className="w-75">{obj?.reasonOfAction}</p>
            )}
          </div>
        </>
      )
    }));

    setRefundJourney(journey);
    setRefundModalData(refundDetails);
  }, [refundData]);

  const onSuccessApproveOrDecline = () => {
    if (callBackFunction) {
      callBackFunction();
      onClearAll();
    }
  };

  const onSubmitRequest = async (values: any) => {
    if (isExecuteOnly) {
      const payload = {
        orderId: refundData?.orderId || '',
        actionBy: tokenData?.fullName || '',
        reasonOfAction: values?.description
      };
      await executeRefundedOrder({
        payload: payload,
        callbackFun: onSuccessApproveOrDecline
      });
    } else {
      const payload = {
        orderId: refundData?.orderId,
        actionBy: tokenData?.fullName,
        reasonOfAction: values?.description
      };

      if (actionType?.approve) {
        const { data } = await approveRefund({
          payload: payload,
          callbackFun: onClearAll
        });
        if (data?.status >= 200 && data?.status <= 300) {
          onSuccessApproveOrDecline();
        }
      }
      if (actionType?.decline) {
        const { data } = await declineRefund({
          payload: payload,
          callbackFun: onClearAll
        });
        if (data?.status >= 200 && data?.status <= 300) {
          onSuccessApproveOrDecline();
        }
      }
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={refundActionModal}
        title={showActionButtons ? 'Refund Action' : 'Refund Details'}
        footer={false}
        onCancel={onClearAll}>
        <Spin spinning={isLoading}>
          <div className="flex flex-col gap-3">
            {refundModalData?.map((item: any, key: number) => (
              <div key={key} className="flex">
                <p className="mr-2 min-w-[140px] flex justify-between capitalize">
                  {item?.label} <span>:</span>
                </p>
                <p className="w-75">{item?.value ? item?.value : '-'}</p>
              </div>
            ))}
            <div>
              <div className="mb-3 flex min-w-[140px] max-w-[140px] justify-between capitalize font-semibold">
                <p>Summary</p>
                <span>:</span>
              </div>
              <Steps
                className="ml-3"
                progressDot
                direction="vertical"
                current={refundJourney?.length}
                items={refundJourney}
              />
            </div>
          </div>

          {showActionButtons ? (
            <Form
              scrollToFirstError
              id={'refundActionForm'}
              layout="vertical"
              form={actionForm}
              onFinish={onSubmitRequest}>
              <div className="my-3 flex">
                <p className="mr-2 mt-1 min-w-[140px] flex justify-between">
                  Description <span>:</span>
                </p>

                <div>
                  <Form.Item
                    className="mb-5 mt-2"
                    name="description"
                    rules={[{ required: true, message: 'Please enter description.' }]}>
                    <TextArea showCount maxLength={100} placeholder="Enter description..." />
                  </Form.Item>
                </div>
              </div>
            </Form>
          ) : null}

          <div className="mt-5 flex justify-between">
            <Button className="m-0" onClick={onClearAll}>
              {showActionButtons ? 'Cancel' : 'Close'}
            </Button>

            {isExecuteOnly ? (
              <Button
                className="m-0 primary-button"
                onClick={() =>
                  setActionType({
                    approve: true,
                    decline: false
                  })
                }
                htmlType={'submit'}
                form={'refundActionForm'}>
                Execute
              </Button>
            ) : showActionButtons ? (
              <div className="flex items-center" style={{ gap: 20 }}>
                <Button
                  className="m-0 primary-button"
                  onClick={() =>
                    setActionType({
                      approve: true,
                      decline: false
                    })
                  }
                  htmlType={'submit'}
                  form={'refundActionForm'}>
                  Approve
                </Button>
                <Button
                  className="m-0 bg-red-600 !text-white"
                  onClick={() =>
                    setActionType({
                      approve: false,
                      decline: true
                    })
                  }
                  htmlType={'submit'}
                  form={'refundActionForm'}>
                  Decline
                </Button>
              </div>
            ) : null}
          </div>
        </Spin>
      </Modal>

      <Modal
        forceRender
        open={showReasonModal}
        title={'Refund Reason'}
        footer={false}
        onCancel={onCloseReasonModal}>
        <p className="font-bold">
          <GetStatus
            currStatus={selectedReason?.status}
            PROCESSING="requested"
            SUCCESS="approved"
            FAILED="declined"
            COMPLETED="executed"
            CUSTOM_LABEL={selectedReason?.status?.toUpperCase()}
          />
        </p>
        <div className="mt-2">
          <ReactMarkdown
            components={{
              ol: ({ children, ...props }) => (
                <ol className="ml-4 list-decimal list-inside" {...props}>
                  {children}
                </ol>
              ),
              ul: ({ children, ...props }) => (
                <ul className="ml-4" {...props}>
                  {children}
                </ul>
              )
            }}>
            {selectedReason?.reason}
          </ReactMarkdown>
        </div>
      </Modal>
    </>
  );
};

export default RefundActions;
