import { Button, DatePicker, Form, Input, Modal, Select, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  IClientDetailsApiResponse,
  IRaiseInvoiceForm,
  IRaiseInvoicePayload
} from '../../../../interfaces/invoicingEngine';
import Formlabel from '../../../CommonComponents/FormLabel';
import { getStatesList } from '../../../../hooks/addressAPIs';
import { IStateList } from '../../../../interfaces/addressHooks';
import { raiseInvoiceFormValidations } from './constants';
import { raiseInvoiceAPI } from '../api';

interface IRaiseInvoiceModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  detailsData: IClientDetailsApiResponse | null;
}

const RaiseInvoiceModal: FC<IRaiseInvoiceModal> = ({
  isModalOpen,
  setIsModalOpen,
  detailsData
}) => {
  const [raiseInvoiceForm] = Form.useForm();
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen && !statesData?.length) {
      handleGetStates();
    }
    if (isModalOpen && detailsData?.zohoDetails?.gstNo) {
      raiseInvoiceForm.setFieldValue('gstNumber', detailsData.zohoDetails.gstNo);
    }
  }, [isModalOpen]);

  const handleGetStates = async () => {
    await getStatesList({
      setData: setStatesData,
      countryCode: 'IN',
      setLoading: setIsModalLoading
    });
  };

  const onClose = () => {
    setIsModalOpen(false);
    raiseInvoiceForm.resetFields();
  };

  const onSubmit = async (formValues: IRaiseInvoiceForm) => {
    const month = new Date(formValues.month).getMonth() + 1;
    const year = new Date(formValues.year).getFullYear();

    const payload: IRaiseInvoicePayload = {
      ...formValues,
      userId: detailsData?.clientDetails?.userId || '',
      averageRate: Number(formValues?.averageRate),
      quantityOfShipment: Number(formValues?.quantityOfShipment),
      month: String(month),
      year: String(year)
    };

    await raiseInvoiceAPI({ setLoading: setIsModalLoading, payload, callBackFunc: onClose });
  };

  return (
    <Modal title="Raise Invoice" open={isModalOpen} onCancel={onClose} footer={false}>
      <Spin spinning={isModalLoading}>
        <div className="min-h-[200px]">
          {!!statesData?.length && (
            <Form form={raiseInvoiceForm} onFinish={onSubmit} scrollToFirstError>
              <div>
                <Formlabel labelText="GST Number" required />
                <Form.Item
                  name="gstNumber"
                  className="mt-1"
                  rules={[
                    { required: true, message: raiseInvoiceFormValidations?.gstNumber?.required },
                    {
                      pattern: raiseInvoiceFormValidations?.gstNumber?.patternValueRegex,
                      message: raiseInvoiceFormValidations?.gstNumber?.patternMessage
                    }
                  ]}>
                  <Input disabled placeholder="Enter gst number" />
                </Form.Item>
              </div>

              <div>
                <Formlabel labelText="Average Rate" required />
                <Form.Item
                  className="mt-1"
                  name="averageRate"
                  rules={[
                    {
                      required: true,
                      message: raiseInvoiceFormValidations.averageRate.required
                    },
                    {
                      pattern: raiseInvoiceFormValidations.averageRate.patternValueRegex,
                      message: raiseInvoiceFormValidations.averageRate.patternMessage
                    }
                  ]}>
                  <Input placeholder="Enter rate" />
                </Form.Item>
              </div>

              <div>
                <Formlabel labelText="Quantity Of Shipment" required />
                <Form.Item
                  className="mt-1"
                  name="quantityOfShipment"
                  rules={[
                    {
                      required: true,
                      message: raiseInvoiceFormValidations.quantityOfShipment.required
                    },
                    {
                      pattern: raiseInvoiceFormValidations.quantityOfShipment.patternValueRegex,
                      message: raiseInvoiceFormValidations.quantityOfShipment.patternMessage
                    }
                  ]}>
                  <Input placeholder="Enter quantity" />
                </Form.Item>
              </div>

              <div>
                <Formlabel labelText="Place Of Supply" required />
                <Form.Item
                  className="mt-1"
                  name="placeOfSupply"
                  rules={[
                    {
                      required: true,
                      message: raiseInvoiceFormValidations.placeOfSupply.required
                    }
                  ]}>
                  <Select
                    showSearch
                    disabled={!statesData?.length}
                    placeholder="Select any state"
                    optionFilterProp="label"
                    options={statesData?.map((item) => ({
                      label: `${item?.name} - ${item?.code}`,
                      value: item?.code
                    }))}
                  />
                </Form.Item>
              </div>

              <div>
                <Formlabel labelText="Month" required />

                <Form.Item
                  name="month"
                  className="mt-1"
                  rules={[
                    {
                      required: true,
                      message: raiseInvoiceFormValidations.month.require
                    }
                  ]}>
                  <DatePicker
                    format="MMMM"
                    className="w-full"
                    placeholder="Select month"
                    picker="month"
                  />
                </Form.Item>
              </div>

              <div>
                <Formlabel labelText="Year" required />

                <Form.Item
                  name="year"
                  className="mt-1"
                  rules={[{ required: true, message: raiseInvoiceFormValidations.year.require }]}>
                  <DatePicker className="w-full" placeholder="Select year" picker="year" />
                </Form.Item>
              </div>

              <div className="flex justify-center">
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </div>
            </Form>
          )}

          {!!(!isModalLoading && !statesData?.length) && (
            <div className="min-h-[200px] h-full flex justify-center items-center">
              <div className="text-center">
                <p className="text-red-400 font-semibold tracking-wide">
                  Something went wrong. Please try again!
                </p>

                <Button className="mt-2 primary-button" onClick={handleGetStates}>
                  Try Again
                </Button>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default RaiseInvoiceModal;
