export const raiseInvoiceFormValidations = {
  averageRate: {
    required: 'Please enter rate',
    patternValueRegex: /^(?!0)\d+$/,
    patternMessage: 'Please enter valid number'
  },
  quantityOfShipment: {
    required: 'Please enter rate',
    patternValueRegex: /^(?!0)\d+$/,
    patternMessage: 'Please enter valid quantity'
  },
  placeOfSupply: {
    required: 'Please enter state'
  },
  gstNumber: {
    required: 'Please enter GST number',
    patternValueRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    patternMessage: 'Please enter a valid GST number'
  },
  month: {
    require: 'Please select any month'
  },
  year: {
    require: 'Please select any year'
  }
};
