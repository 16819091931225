import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { Dispatch, FC, SetStateAction } from 'react';
import {
  IClientDetailsApiResponse,
  ITaxGroupDetails
} from '../../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { FaRegCheckCircle } from 'react-icons/fa';

interface IContactTaxInformationModal {
  detailsData: IClientDetailsApiResponse | null;
  taxInformationModal: boolean;
  setTaxInformationModal: Dispatch<SetStateAction<boolean>>;
}
const ContactTaxInformation: FC<IContactTaxInformationModal> = ({
  taxInformationModal,
  setTaxInformationModal,
  detailsData
}) => {
  const onClose = () => {
    setTaxInformationModal(false);
  };

  const columns: ColumnsType<ITaxGroupDetails> = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (data) => <GetCopyTextDiv text={data} />
    },
    {
      title: 'Tax Specific Type',
      dataIndex: 'taxSpecificType',
      width: 150,
      render: (data) => <GetCopyTextDiv text={data} />
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      width: 100,
      render: (data) => <GetCopyTextDiv text={data ? `${data} %` : ''} />
    },
    {
      title: 'Deleted',
      dataIndex: 'deleted',
      width: 100,
      render: (deleted) => (deleted ? <FaRegCheckCircle color="green" size={20} /> : '--')
    }
  ];
  return (
    <Modal
      width={800}
      onCancel={onClose}
      open={taxInformationModal}
      footer={false}
      title="Contact Tax Information">
      <div className="flex flex-col gap-2 my-5">
        <div className="flex gap-3">
          <p className="flex w-[120px] justify-between">
            Tax Specific Type <span>:</span>
          </p>
          <GetCopyTextDiv text={detailsData?.zohoDetails?.contactTaxInformation?.taxSpecificType} />
        </div>

        <div className="flex gap-3">
          <p className="flex w-[120px] justify-between">
            Tax Specification <span>:</span>
          </p>
          <GetCopyTextDiv
            text={detailsData?.zohoDetails?.contactTaxInformation?.taxSpecification}
          />
        </div>
      </div>
      <Table
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={detailsData?.zohoDetails?.contactTaxInformation?.taxGroupDetails}
      />
    </Modal>
  );
};

export default ContactTaxInformation;
