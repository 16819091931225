import React, { useState } from "react";
import { Drawer, Form, Input, Button, Select } from "antd";
import { CreatepickupAddressAPI } from "../../api";
import { Option } from "antd/es/mentions";

interface PickupAddressDrawerProps {
  visible: boolean;
  onClose: () => void;
  userId: string;
  refreshData: () => void;
  users: { label: string; value: string }[];
}

const PickupAddressDrawer: React.FC<PickupAddressDrawerProps> = ({ visible, onClose, userId, refreshData, users }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState<string>('');

  const onFinish = async (values: any) => {
    try {
      console.log(values , 21)
      console.log(values.mobile , 22)
      setLoading(true);
      const body = { ...values, userId };
      await CreatepickupAddressAPI(body);
      await refreshData();
      form.resetFields(); 
    } catch (error) {
      console.error("Error creating pickup address:", error);
    } finally {
      setLoading(false);
      onClose(); 
    }
  };
  const tagOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Home',
      value: 'Home'
    },
    {
      label: 'Office',
      value: 'Office'
    },
  ];
  return (
    <Drawer
      title="Add Pickup Address"
      width={720}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="flex flex-col gap-2">
       <div className="flex gap-2">
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input />
            </Form.Item>
       </div>
          <div className="flex gap-2">
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter email" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="flex gap-2">
          <Form.Item
          name="mobile"
          label="Mobile Number"
          rules={[
            { required: true, message: "Please enter mobile number" },
            { pattern: /^[0-9]{10}$/, message: "Mobile number must be exactly 10 digits" },
          ]}
        >
          <Input
            type="number"
            maxLength={10}
            pattern="[0-9]*"
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldsValue({ mobile: value ? Number(value) : "" });
            }}
          />

            </Form.Item>

            <Form.Item
          name="alternateMobile"
          label="Alternate Mobile Number"
          rules={[
            { required: true, message: "Please enter mobile number" },
            { pattern: /^[0-9]{10}$/, message: "Mobile number must be exactly 10 digits" },
          ]}
        >
          <Input
            type="number"
            maxLength={10}
            pattern="[0-9]*"
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldsValue({ alternateMobile: value ? Number(value) : "" });
            }}
          />
          </Form.Item>
          
          </div>
          <div className="flex gap-2">
            <Form.Item
              name="line1"
              label="Line 1"
              rules={[{ required: true, message: "Please enter line1 address" }]}
            >
              <Input.TextArea  />
            </Form.Item >
            <Form.Item
              name="line2"
              label="Line 2"
              rules={[{ required: true, message: "Please enter line2 address" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="line3"
              label="Line 3"
              rules={[{ required: true, message: "Please enter line3 address" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
          <div className="flex gap-2">
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please enter state" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="flex gap-2">
            <Form.Item
              name="stateCode"
              label="State Code"
              rules={[{ required: true, message: "Please enter state code" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="pincode"
              label="Pin Code"
              rules={[{ required: true, message: "Please enter pin code" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="flex gap-2">
            <Form.Item  name="tag"  label="Tag" > 
                <Select placeholder="Select tag" style={{ width: '200px' }} options={tagOptions} >
                 
                </Select>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PickupAddressDrawer;
