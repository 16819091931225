import React, { FC, useEffect, useState } from 'react';
import { IPincodeTableData } from '../../../../../interfaces/handlePincode';
import { Button, Input, Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import YellowCircle from '../../../../CommonComponents/YellowCircle';

interface IPincodesTable {
  pincodesData: number[];
}
const PincodesTable: FC<IPincodesTable> = ({ pincodesData }) => {
  const [tableData, setTableData] = useState<IPincodeTableData[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    let newData = [] as IPincodeTableData[];
    newData = pincodesData?.map((pin, index) => ({
      rowKey: index + 1,
      pincode: pin
    }));

    setTableData(newData);
  }, [pincodesData]);

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    setSearchText('');
    handleSearch([], confirm, 'pincode');
  };

  const getColumnSearchProps = (dataIndex = 'pincode'): ColumnType<IPincodeTableData> => ({
    title: (
      <div className="flex items-center">
        Pincode
        {searchText ? <YellowCircle /> : null}
      </div>
    ),
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="p-2 flex flex-col gap-2">
        <Input
          className="w-[200px]"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
        />
        <div className="flex gap-2">
          <Button
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="w-1/2 primary-button">
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            className="w-1/2">
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record: IPincodeTableData) =>
      record['pincode'].toString().toLowerCase().includes(value?.toString().toLowerCase()),
    render: (text) => (searchText ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text)
  });

  const tableColumns: ColumnsType<IPincodeTableData> = [
    {
      dataIndex: 'pincode',
      width: 200,
      render: (code) => <div className="flex justify-center">{code}</div>,
      ...getColumnSearchProps('pincode')
    }
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="w-[90%] md:w-[500px]">
        <Table
          rowKey={'rowKey'}
          columns={tableColumns}
          pagination={{ pageSize: 10 }}
          dataSource={tableData}
        />
      </div>
    </div>
  );
};

export default PincodesTable;
