import { ErrorMessage, SuccessMessage } from '../../../utils/ResponseMessages';
import { httpClient } from '../../../utils/apiRequest';
import { Dispatch, SetStateAction } from 'react';
import {
  IAddClientPayload,
  IClientDetailsApiResponse,
  IClientList,
  IRaiseInvoicePayload
} from '../../../interfaces/invoicingEngine';
import { IInvoiceDetails, IInvoiceList } from '../../../interfaces/invoiceListAndDetails';

export const getClientList = async ({
  setLoading,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IClientList>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get('/invoicing/clients?skip=0&limit=10');
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ count: 0, clientsList: [] });
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const addNewClient = async ({
  setLoading,
  payload,
  callBack
}: {
  payload: IAddClientPayload;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBack?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post('/invoicing/users', payload);
    if (callBack) callBack();
  } catch (error: any) {
    console.error('Error while adding client', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getClientDetails = async ({
  setLoading,
  clientId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IClientDetailsApiResponse | null>>;
  clientId: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/clients/${clientId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    console.error('Error while fetching client list', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const verifyUserApi = async ({
  setLoading,
  emailId,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  emailId: string;
  callbackFun?: (id: string) => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/user/${emailId}`);
    if (callbackFun) callbackFun(response?.data?.data?.user_id);
  } catch (error: any) {
    if (callbackFun) callbackFun('');
    console.error('Error while raise invoice API', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const raiseInvoiceAPI = async ({
  setLoading,
  payload,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IRaiseInvoicePayload;
  callBackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post(`/invoicing/raise`, payload);
    SuccessMessage('Request raised successfully.');
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    console.error('Error while raise invoice API', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceList = async ({
  setLoading,
  clientId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  clientId: string;
  setData: Dispatch<SetStateAction<IInvoiceList>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(
      `/invoicing/invoices/?skip=0&limit=10&clientId=${clientId}`
    );
    setData({ count: response?.data?.data?.count, invoiceList: response?.data?.data?.invoiceList });
  } catch (error: any) {
    setData({ count: 0, invoiceList: [] });
    console.error('Error while raise invoice API', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getInvoiceDetails = async ({
  setLoading,
  invoiceId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  invoiceId: string;
  setData: Dispatch<SetStateAction<IInvoiceDetails | null>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/invoicing/invoice/${invoiceId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    console.error('Error while raise invoice API', error?.response?.data?.message);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
