import { Button, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { doc, onSnapshot } from '@firebase/firestore';

import { generateBarcode } from '../api';
import { db } from '../../../../firebase/firebase';
import { ErrorMessage } from '../../../../utils/ResponseMessages';
interface IGenerateReceiptButton {
  id: string;
  userId: string;
}
const GenerateBarcodeButton: FC<IGenerateReceiptButton> = ({ id, userId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [barcodeCounts, setBarcodeCounts] = useState<{
    currentCount: number;
    totalCount: number;
  }>({
    currentCount: 0,
    totalCount: 0
  });
  const onGenerateClick = async () => {
    const payload = {
      id,
      userId
    };
    setBarcodeCounts({
      currentCount: 0,
      totalCount: 0
    });
    ecboBarcodeRealtimeCounts(id);
    await generateBarcode({
      setLoading: setIsLoading,
      payload: payload
    });
  };

  const ecboBarcodeRealtimeCounts = async (id: string) => {
    const docRef = doc(db, 'ongoingProcess', `ECBO-Barcode-${id}`);

    setIsLoading(true);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setBarcodeCounts({
            currentCount: snapshot.data()?.currentCount,
            totalCount: snapshot.data()?.totalCount
          });
        }
      },
      (error) => {
        console.error('Error fetching Firestore document:', error);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  };

  const getTitle = () => {
    return isLoading
      ? `${barcodeCounts?.currentCount ? barcodeCounts?.currentCount : 0} / ${
          barcodeCounts?.totalCount
        }`
      : 'Generate';
  };

  return (
    <>
      <Tooltip title={isLoading ? getTitle() : ''}>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={onGenerateClick}
          icon={<MdOpenInNew />}>
          {getTitle()}
        </Button>
      </Tooltip>
    </>
  );
};

export default GenerateBarcodeButton;
