import { Button, Table, Tabs, TabsProps, Form, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import UserMenu from '../../ManageMenu/api';
import { UserFormDetails } from '../../Users/interfaces/UserData';
import UsersData from '../../Users/api';
import moment from 'moment';
import { finexUsersListAPI, } from '../api';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { getUserDetails } from '../api';
import FintechUser from '../UserDetails';

const FintechUserList: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = UserMenu();
  const [showForm, setShowForm] = useState<boolean>(false);
  const { UsersList } = UsersData();
  const [userList, setUserList] = useState<any[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(20)
  const [activeTab, setActiveTab] = useState<string>('ENABLED')


  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (status = activeTab) => {
    const userListApiRes = await finexUsersListAPI({ pageNumber, pageLimit, status });
    console.log(userListApiRes);
    setUserList(userListApiRes.list)
  };

  const handleViewDetails = async (userDetails: any) => {
    console.log(userDetails, 35);
    const response = await getUserDetails(userDetails.userId);
    console.log('User Details:', response);
    navigate(`/user-details-finx/${userDetails.userId}`, {
      state: { userDetails: response},
    });
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'ENABLED',
      label: 'Verified Users',
    },
    {
      key: 'PENDING',
      label: 'Pending Users',
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      width: '6%',
      render: (getUsersData) => (
        <>
          <h3 className="font-bold text-blue-500" onClick={() => handleViewDetails(getUsersData)}>{getUsersData?.firstName}</h3>
          <GetCopyTextDiv text={getUsersData?.email} />

        </>
      ),
      responsive: ['sm'],

    },
    {
      title: 'User Id',
      dataIndex: 'userId',
      key: 'userId',
      width: '6%',
      responsive: ['sm'],
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Pickup Address',
      width: '3%',
      align:"center",
      fixed: 'right',
      render: (record) => (
        <span className="font-bold text-blue-500" style={{textDecoration:"underline",textDecorationStyle:"dotted"}}
        onClick={() => navigate(`/pickupAddress-finx/${record.userId}`)}>Addresses</span>
      ),
      responsive: ['sm'],
    },    
    {
      title: 'Charges($)',
      width: '3%',
      fixed: 'right',
      align:"center",
      render: (record) => (
        <div>
          <Button
            className="secondary-button"
            onClick={() => navigate(`/charges-finx/${record.userId}`)}
          >
            Charges
          </Button>
        </div>
      ),
    },
    {
      title: 'Booking Request',
      width: '6%',
      responsive: ['sm'],
      fixed: 'right',
      align: 'center',
      render: (record) => (
        <div>
          <Button
            className="me-3 ms-3" style={{width:"140px"}}  type='primary' onClick={() => navigate(`/booking-request-finx/${record.userId}`)}> Booking Request
          </Button>
        </div>
      ),
    },
    {
      title: 'Bulk',
      width: '4%',
      align: 'center',
      responsive: ['sm'],
      fixed: 'right',
      render: (record) => (
        <div>
          <Button
            className="me-3 primary-button" type='default' onClick={() => navigate(`/bulkOrder-list-finx/${record.userId}`)}>Bulk Order
          </Button>
        </div>
      ),
    },
  ];
  const onTabChange = async (tab: any) => {
    setActiveTab(tab)
    getUsers(tab) 
  }
  return (
    <>
      <div className="top-row  justify-between mb-2 px-3 sm-w-48">
        <h2 className="text-xl font-bold m-0">Users</h2>
        <div className='fintech-user-tab'>
          <Tabs size='middle' defaultActiveKey={activeTab} items={tabs} onChange={onTabChange} ></Tabs>
        </div>
      </div>
      <div>
        <Table
          scroll={{ x: 1600 }}
          columns={columns}
          dataSource={userList}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default FintechUserList;
