import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button,Form, message,Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IServiceProvidersListProps } from '../../../../../interfaces/serviceProvider';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import {  DeleteOutlined, SnippetsFilled } from '@ant-design/icons';
import { deleteBulk } from '../../api';

const { Paragraph } = Typography;
interface IHandlePortalServiceability {
  currentPortalName: string;
  isLoading: boolean;
  bulkOrderList: IServiceProvidersListProps[];
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeKey:string;
  setSelectedRaws: Dispatch<SetStateAction<any[]>>;

}
const HandleBulkOrderList: FC<IHandlePortalServiceability> = ({
  isLoading,
  bulkOrderList,
  setActiveTab,
  activeKey,
  setSelectedRaws

}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedBulkId, setSelectedBulkId] = useState('');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [providerForm] = Form.useForm();
  const [bulkOrdersList, setBulkOrdersList] = useState<{orders: { [key: string]: any }[];totalPage: number;}>({orders: [],totalPage: 0});
  const start = (currentPage - 1) * pageLimit;
  const end = start + pageLimit;
  const currentPageData = bulkOrdersList?.orders.slice(start, end);
  const [cartStatus, setCartStatus] = useState<number>();
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [cartListData, setCartListData] = useState<any[]>([]); 
  const [selectedDeleteBulkId, setSelectedDeleteBulkId] = useState<string | null>(null);
  const [bulkId, setbulkId] = useState<string | null>(null);


  useEffect(() => {
    setBulkOrdersList({ orders: bulkOrderList, totalPage: 0 });
  }, [bulkOrderList]);

  const handleBulkOrderIdClick = (bulkId:string) => {
    setSelectedBulkId(bulkId);
    setDrawerVisible(true);
    console.log(`Bulk Order ID clicked: ${bulkId}`,11);
  };

  const onClose = () => {
    setOpen(false);
  }
  
  const onCloseDelete = () => {
    setOpenDelete(false);
    setSelectedDeleteBulkId(null);
  };
  
  const onDelete = async () => {
    if (!selectedDeleteBulkId) {
      message.error("No order selected for deletion.");
      return;
    }
    console.log("Selected bulk ID for deletion:", selectedDeleteBulkId);
  
    try {
      await deleteBulk(selectedDeleteBulkId);
      message.success("Bulk deleted successfully.");
  
      console.log("Before deletion:", bulkList, cartListData);
  
      setBulkList((prevData) =>
        prevData.filter((item) => item.bulkId !== selectedDeleteBulkId)
      );
      setCartListData((prevCartData) =>
        prevCartData.filter((item) => item.bulkId !== selectedDeleteBulkId)
      );
  
      console.log("After deletion:", bulkList, cartListData);
    } catch (error) {
      message.error("Failed to delete bulk.");
      console.error("Delete error:", error);
    }
  };
  
  const viewOrderDetails = async (orderType: string, record: any) => {
    setSelectedBulkId(record._id);
    if(orderType === 'success'){
      setCartStatus(2)
      setDrawerVisible(true);
    }else if(orderType === 'processing'){
      setCartStatus(1)
      setDrawerVisible(true);
    }else if(orderType === 'dispatchedOrders'){
      setCartStatus(5)
      setDrawerVisible(true);
    }
    else{
      setCartStatus(0)
      setDrawerVisible(true);
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'Bulk Order Id',
      dataIndex: 'bulkId',
      fixed: 'left',
      width: 120,
      key: 'bulkId',
      render: (orderId) => (
        <Paragraph copyable={{ text: orderId }} onClick={() => handleBulkOrderIdClick(orderId)} style={{ color: "#1677ff", textDecoration: "underline" }}>
          <b>{orderId ? orderId : 'NA'}</b>
        </Paragraph>
      ),
    },
    {
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      title: 'Date',
      width: 80,
       align: 'center',
      render: (date) => {
        const bulkOrderDate = moment(date).format('DD-MM-YYYY hh:mm:ss');
        return (
          <div>{bulkOrderDate==='Invalid date' ?  '-':bulkOrderDate}</div>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'totalOrders',
      key: 'ordetotalOrders',
      width: 50,
      align: 'center',
      render: (orders) => <div>{orders}</div>,
    }, 
    {
      title: 'Service Provider',
      dataIndex: 'serviceProvider',
      key: 'serviceProvider',
      width: 70,
      align: 'center',
      render: (orders) => <div>{orders}</div>,
    },
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
      width: 90,
      align: 'center',
      render: (orders) => <div>{orders}</div>,
    },
    
  ];
  if (activeKey === 'Open To Ship')
     {
    columns.push(
      {
          title: 'To Be Dispatched',
          key: 'ToBeDispatched',
          dataIndex: 'ToBeDispatched',
          align: 'center',
          width: 70,
          render: (text, record) => {
            if (record.serviceType === 'SELF_DROP') {
              return(
                <Button className="primary-button" >
                Ship Now
              </Button>
              );
            }else{
              return(
                <Button className="secondary-button" disabled>
                Dispatch
              </Button>
              )
            }
          }
      },
      {
        title: 'Notices (ZIP)',
        key: 'bulkShipmentLink',
        width: 100,
        align: 'center',
        render: (orders: any) => {
          const isDisabled = !orders.bulkShipmentLink; 
      
          return (
            <Button
              icon={<FaDownload />}
              href={orders.bulkShipmentLink || '#'}  
              target="_blank"
              className="primary-button"
              disabled={isDisabled} 
            >
              Download
            </Button>
          );
        }
      },
      {
        title: 'Address Lable',
        key: 'bulkAddressLabelLink',
        width: 110,
        align: 'center',
        render: (orders:any,record) => {
         if(record.serviceType === 'SELF_DROP'){
          return(
           <h4 >--</h4>
          )
         }else{
          return(
            <Button
            icon={<SnippetsFilled />}
             // href={orders.bulkExcelLink}
             target="_blank"
             className="primary-button">
             Lable Download
           </Button>
          )
         }
        }
      },
      {
        title: 'Dl Acknowledge Label',
        key: 'bulkAddressLabelLink',
        width: 110,
        align: 'center',
        render: (orders:any,record) => {
          if(record.serviceType === 'SELF_DROP'){
            return(
             <h4 >--</h4>
            )
           }else{
            return (
              <Button
               icon={<SnippetsFilled />}
                // href={orders.bulkExcelLink}
                target="_blank"
                className="primary-button">
                Label Download
              </Button>
            )
           }
         
        }
        
      },
      {
        title: 'Shipment (XLSX)',
        key: 'bulkExcelLink',
        width: 100,
        align: 'center',
        render: (orders:any,record) => {
         if(record.serviceType === 'SELF_DROP'){
          return(
            <Button
              // icon={<FaDownload />}
              href={orders.bulkExcelLink}
              target="_blank"
              className="primary-button" disabled>
              Ship Now
            </Button>
          )
         }else{
          return(
            <Button
            icon={<FaDownload />}
            href={orders.bulkExcelLink}
            target="_blank"
            className="primary-button" >
            Download
          </Button>
          )
         }
        }
      },
      {
        title: 'Delete',
        key: 'Delete',
        dataIndex: 'delete',
        align: 'center',
        width: 45,
        render: (_: any, record: any) => {
          const showDeleteModal = () => {
            setSelectedDeleteBulkId(record.bulkId);
            setOpenDelete(true);
          };
          return (
            <DeleteOutlined style={{ color: '#1890ff' }} onClick={showDeleteModal} />
          );
        }
      },
  )
}

if(activeKey === 'Shipment Placed'){
  columns.splice(5,0,
    {
      title: 'Lable (PDF)',
      key: 'bulkLableLink',
      width: 150,
      align: 'center',
      render: (text, record, index) => {
        const getShipmentLabel = async(bulkId:string) => {
       
          const shipmentLableDetails :any= await ({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
        }
        return (
          	<Button
                onClick={()=>getShipmentLabel(record._id)}
                icon={<SnippetsFilled />}
                target="_blank"
                className="primary-button"
                disabled={record.failedOrders === record.orders}>
                Lable Download
			      </Button>
        )
      }
    },
  )
  columns.splice(6,0,
    {
      title: 'Indiapost Ack. slip',
      key: 'bulkAcknowledgementSlip',
      width: 150,
      align: 'center',
      render: (text, record, index) => {
        const bulkAcknowledgement = async(bulkId:string) => {
          const shipmentLableDetails :any= await ({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
        }
        return (
          	<Button
                onClick={()=>bulkAcknowledgement(record._id)}
                icon={<SnippetsFilled />}
                target="_blank"
                className="primary-button"
                disabled={record.failedOrders === record.orders}>
                Lable Download
			      </Button>
        )
      }
    },
  )
  columns.splice(3,0,
   {
    title: 'Success',
    dataIndex: 'successOrders',
    key: 'successOrders',
    width: 80,
    align: 'center',
    render: (successOrders, record) => (
      <Tag color="green" style={{cursor:"pointer"}} onClick={() => viewOrderDetails('success', record)}> {successOrders}</Tag>
    )
  },
  {
    title: 'Failed',
    dataIndex: 'failedOrders',
    key: 'failedOrders',
    width: 150,
    align: 'center',
    render: (failedOrders, record) => (
      <div>
      <Tag color="red" style={{ cursor: "pointer" }} onClick={() => viewOrderDetails('failed', record)}>
        {failedOrders}
      </Tag>
      <Button type="link" style={{ marginLeft: 8 }}>
        Retry Order
      </Button>
    </div>
    )
  },

  )
}

if(activeKey === 'To Be Dispatched'){

  columns.splice(3, 0,
    {
      title: 'Proccess Orders',
      dataIndex: 'inProccessOrders',
      key: 'inProccessOrders',
      width: 100,
      align: 'center',
      render: (inProccessOrders, record) => (
        <Tag color="yellow" style={{cursor:"pointer"}} onClick={() => viewOrderDetails('processing', record)}> {inProccessOrders}</Tag>
      )
    },
    {
      title: 'Dispatched Orders',
      dataIndex: 'toBeDispatchedOrders',
      key: 'toBeDispatchedOrders',
      width: 100,
      align: 'center',
      render: (toBeDispatchedOrders, record) => (
        <div>
          <Tag color="green" style={{ cursor: "pointer" }} onClick={() => viewOrderDetails('dispatchedOrders', record)}>
            {toBeDispatchedOrders}
          </Tag>
        </div>
      )
    },
    {
      title: 'Address Lable',
      key: 'bulkAddressLabelLink',
      width: 150,
      align: 'center',
      render: (text, record, index) => {

        const getShipmentLabel = async(bulkId:string) => {
          const shipmentLableDetails :any= await ({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
        }
        return (
          <Button
            onClick={()=>getShipmentLabel(record._id)}
            icon={<SnippetsFilled />}
            target="_blank"
            className="primary-button"
            disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
            Lable Download
          </Button>
        )
      }
    },
    {
      title: 'Dl Acknowledge Label',
      key: 'bulkAddressLabelLink',
      width: 130,
      align: 'center',
      render: (text, record, index) => {

        const getShipmentLabel = async(bulkId:string) => {
          const shipmentLableDetails :any= await ({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
        }
        return (
          <Button
            onClick={()=>getShipmentLabel(record._id)}
            icon={<SnippetsFilled />}
            target="_blank"
            className="primary-button"
            disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
            Lable Download
          </Button>
        )
      }
    },
    {
      title: 'Shipment (XLSX)',
      key: 'bulkExcelLink',
      width: 150,
      align: 'center',
      render: (orders:any) => {
        return (
          <Button
            icon={<FaDownload />}
            href={orders.bulkExcelLink}
            target="_blank"
            className="primary-button">
            Download
          </Button>
        )
      }
    }
  );
  columns.pop();
}


const handleSubmit = async (formValues:any) => {
  setOpen(false);
  await ({bulkId:bulkId,serviceProvider:formValues.serviceProvider,serviceType:formValues.serviceType})
};

  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-between"/>
        <div>
        <Table
        bordered
        columns={columns}
        loading={isLoading}
        rowKey={'_id'}
        scroll={{ x: 1616 }}
        dataSource={currentPageData}
      />
    </div>
      </div>
      <Modal
        open={open}
        onCancel={onClose}
        footer={false}>
        <div >

          <Form form={providerForm} layout="vertical" id="addProviderForms" onFinish={handleSubmit}>
            <div className="flex flex-col gap-2">
           <p>Are you sure you want to ship the bulk? </p>
            </div>
            <div className="my-5 flex justify-end gap-5">
              <Button  className="px-5" onClick={onClose}>Cancel</Button>
              <Button htmlType="submit" form="addProviderForms" className="px-5 primary-button">
                Ship
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Delete Bulk Order"
        visible={openDelete}
        onCancel={onCloseDelete}
        footer={false}
        >
        <div>
          <div className="flex flex-col gap-2">
            <p>Are you sure you want to delete the selected bulk order?</p>
          </div>
          <div className="my-5 flex justify-end gap-5">
            <Button onClick={onCloseDelete}>Cancel</Button>
            <Button onClick={onDelete} type="primary" >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
            </>
          );
        };

export default HandleBulkOrderList;
