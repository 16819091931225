import { Button, Modal,  Pagination,  PaginationProps, Select, Tabs, TabsProps } from "antd";
import React, {  useEffect, useRef, useState } from "react";
import HandleBulkOrderList from "./HandleBulkOrderList";
import { bulkOrdersList, getFinTechUsers, getSuccessOrdersDeatils } from "../api";
import { showMessage } from "../../../../utils/ResponseMessages";
import { ReloadOutlined } from "@ant-design/icons";

const BulkOrderList = () => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string>('Open To Ship');
  const [bulkOrderList, setBulkOrderList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [userId, setUserId] = useState<string>('');
  const [users, setUsers] = useState<{label:string,value:string}[]>([{label:"",value:""}]);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(""); 
  const [selectedRaws, setSelectedRaws] = useState<any[]>([]); 

  const getUsers = async() => {
    const usersData= await getFinTechUsers({setLoading:setIsLoading});
    const dropDownInterFace: { label: string; value: string }[] = [];
    for(let i=0;i<usersData.length;i++){
      dropDownInterFace.push({label:usersData[i].identifier,value:usersData[i].userId})
    }
    setUsers(dropDownInterFace);
  }

  useEffect(() => {
    getUsers()
    if (containerRef.current) {
      const tabs = containerRef.current.getElementsByClassName('ant-tabs-tab');
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i] as HTMLDivElement;
        tab.style.width = '100%';
        tab.style.textAlign = 'center';
      }
    }
  }, []);

  const handleGetServiceabilityList = async (cartStatus:number,userId:string|null,pageNo?:number|null,isnewTab=false) => {
    setIsLoading(true);
    await bulkOrdersList({
      payload: { pageNumber: isnewTab?1:(pageNo||currentPage), pageLimit,cartStatus:cartStatus ,userId},
      setData: setBulkOrderList,
      setLoading: setIsLoading,
      setTotalCount:setTotalCount
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
    let cartStatus = 4;
    if(activeTab === 'Open To Ship') cartStatus = 4;
    if(activeTab === 'In-Process') cartStatus = 1;
    if(activeTab === 'Shipment Placed') cartStatus = 2;
    if(activeTab === 'To Be Dispatched') cartStatus = 5;

    if(userId === null||userId === '') {
      alert('Please enter userId to search');
      setBulkOrderList([]);
      return
    }
    handleGetServiceabilityList(cartStatus, userId,pageNo);
  };
  const onTabChange = (keyName: string) => {
    setActiveTab(keyName);
    setCurrentPage(1)
    setPageLimit(20)
    let cartStatus = 4;
    if(keyName === 'Open To Ship') cartStatus = 4;
    if(keyName === 'In-Process') cartStatus = 1;
    if(keyName === 'Shipment Placed') cartStatus = 2;
    if(keyName === 'To Be Dispatched') cartStatus = 5;
    if(userId === null||userId === '') {
      alert('Please enter userId to search');
      setBulkOrderList([]);
      return
    }
    handleGetServiceabilityList(cartStatus, userId,1,true);
  };
  const handleUser = async(value:any) => {
    setUserId(value);
    let cartStatus = 4;
    if(activeTab === 'Open To Ship') cartStatus = 4;
    if(activeTab === 'In-Process') cartStatus = 1;
    if(activeTab === 'Shipment Placed') cartStatus = 2;
    if(activeTab === 'To Be Dispatched') cartStatus = 5;

    if(userId === '') {
      setBulkOrderList([]);
    }
    handleGetServiceabilityList(cartStatus, value,currentPage);
  };
  const items: TabsProps['items'] = [
      {
        key: 'Open To Ship',
        label: 'Open To Ship',
        children: (
          <HandleBulkOrderList
            currentPortalName="Ship To"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            bulkOrderList={bulkOrderList}
            setActiveTab={setActiveTab}
            activeKey={activeTab}
            userId={userId}
            setSelectedRaws={setSelectedRaws}
          />
        )
      },
      {
        key: 'To Be Dispatched',
        label: 'To Be Dispatched',
        children: (
          <HandleBulkOrderList
            currentPortalName="Dispatch"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            bulkOrderList={bulkOrderList}
            setActiveTab={setActiveTab}
            activeKey={activeTab}
            userId={userId}
            setSelectedRaws={setSelectedRaws}
          />
        )
      },
      {
        key: 'In-Process',
        label: 'In-Process',
        children: (
          <HandleBulkOrderList
            currentPortalName="In-Process"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            bulkOrderList={bulkOrderList}
            setActiveTab={setActiveTab}
            activeKey={activeTab}
            userId={userId}
            setSelectedRaws={setSelectedRaws}
          />
        )
      },
      {
        key: 'Shipment Placed',
        label: 'Shipment Placed',
        children: (
          <HandleBulkOrderList
            currentPortalName="Ship"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            bulkOrderList={bulkOrderList}
            setActiveTab={setActiveTab}
            activeKey={activeTab}
            userId={userId}
            setSelectedRaws={setSelectedRaws}
          />
        )
      },
    ];

    const handleReportClick = () => {
      setOpenReport(true);
    };

    const reportType: {
      label: string;
      value: string;
    }[] = [
      {
        label: 'Success Report',
        value: 'success',
      },
      {
        label: 'Faield Report',
        value: 'failed',
      },
      {
        label: 'All Report',
        value: 'all',
      },
    ];

    const selectedReportTypeData = (value:any) => {
      setSelectedReportType(value);
    }

    const handleReport = async() => {
      if(!selectedReportType){
        showMessage('Please select report type',400);
        return
      }
      let cartStatus;
      if(selectedReportType === 'success') {
        cartStatus = 2;
      }else if(selectedReportType === 'failed') {
        cartStatus = 0;
      }else {
        cartStatus = [0,1,2,3,4,5];
      }
      const bulkIds:any=[];
      for (let i = 0; i < selectedRaws.length; i++) {
        const element = selectedRaws[i];
        bulkIds.push(element._id);
      }
      const createxlsx= await getSuccessOrdersDeatils({
          bulkIds: bulkIds,
          cartStatus: cartStatus
      });
      if(createxlsx?.data?.url){
        window.open(createxlsx?.data.url,'_blank'); 
      }
      setOpenReport(false)  
    }
    const handleRefresh = () => {
      let cartStatus = 4;
        if(activeTab === 'Open To Ship') cartStatus = 4;
        if(activeTab === 'In-Process') cartStatus = 1;
        if(activeTab === 'Shipment Placed') cartStatus = 2;
        if(activeTab === 'To Be Dispatched') cartStatus = 5;
      handleGetServiceabilityList(cartStatus, userId, currentPage);
    }
    return (
      <div>
      <div className="mb-4 flex items-center justify-between">
        <div className='flex items-center ' style={{gap:'10px'}}>
          <h2 className="text-xl font-normal m-0">Bulk Order List</h2>
          <Select placeholder="Select user name" options={users} onChange={handleUser} style={{width:'200px'}} />
        </div>
        <div className="flex items-center">
        <Button size="large"   onClick={handleRefresh} className="mr-8" icon={ <ReloadOutlined  />}>    
        Referesh
        </Button>
        <Button size="large"  className="m-0 ant-btn-primary"  onClick={handleReportClick}>Report</Button>
        <Pagination
          responsive={true}
          current={currentPage}
          pageSize={pageLimit}
          pageSizeOptions={[20, 30, 50]}
          total={totalCount|| 0}
          onChange={onPageChange}
        />
        </div>
        <Modal
          open={openReport}
          onCancel={() => setOpenReport(false)}
          footer={false}
          width={300}
          >
         <b>Select Report Type </b> 
          <div style={{marginTop:20}} >
         <Select placeholder="Select type" options={reportType} onChange={selectedReportTypeData} style={{width:'100%'}}/>
            <div className="my-5 flex justify-end gap-5" style={{marginTop:100}} >
              <Button  onClick={() => setOpenReport(false)} >Close</Button>
              <Button className="primary-button" onClick={ handleReport}>Submit</Button>
            </div>
          </div>
        </Modal>
      </div>
      <div ref={containerRef} className="tabs-container relative">
        <Tabs
          animated
          type="card"
          activeKey={activeTab}
          onChange={onTabChange}
          size='small'
          className="md:w-[100%] w-full mx-auto p-2 border-0">
          {items.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={<div style={{ width: '150px', textAlign: 'center' }}>{item.label}</div>}>
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
    );
}

    export default BulkOrderList;