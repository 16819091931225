import React from 'react';

const Order: React.FC = () => {

  return (
    <>
      <div className="top-row  justify-between mb-2 px-3 sm-w-48">
        <h2 className="text-xl font-bold m-0">Orders</h2>
      </div>
      <div>
      </div>
    </>
  );
};
export default Order;
