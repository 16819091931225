import { Modal, Table } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  IInvoiceDetailsItems,
  IInvoiceItemTaxes
} from '../../../../interfaces/invoiceListAndDetails';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';

interface IItemTaxesModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  itemTaxesData: IInvoiceDetailsItems | null;
  setItemTaxesData: Dispatch<SetStateAction<IInvoiceDetailsItems | null>>;
}
const ItemTaxesModal: FC<IItemTaxesModal> = ({
  isModalOpen,
  setIsModalOpen,
  itemTaxesData,
  setItemTaxesData
}) => {
  const itemDetailsColumns: ColumnsType<IInvoiceItemTaxes> = [
    {
      title: 'Tax Id',
      dataIndex: 'tax_id',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Tax Name',
      dataIndex: 'tax_name',
      width: 200,
      render: (name) => <GetCopyTextDiv text={name} />
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      width: 200,
      render: (amt) => <p className="text-blue-400 font-semibold ">{amt}</p>
    }
  ];

  const onClose = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setItemTaxesData(null);
    }, 500);
  };
  return (
    <Modal
      width={800}
      footer={false}
      onCancel={onClose}
      title={`Item Taxes: ( ${itemTaxesData?.accountId} )`}
      open={isModalOpen}>
      <div className="my-5">
        <Table
          scroll={{ x: 600 }}
          className="max-w-[800px]"
          columns={itemDetailsColumns}
          dataSource={itemTaxesData?.itemTaxes}
        />
      </div>
    </Modal>
  );
};

export default ItemTaxesModal;
