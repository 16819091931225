import { Button, Form, Spin, Steps,notification,Upload} from "antd";
import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails, uploadBookingReq } from "../api";

const { Dragger } = Upload;

const UploadBooking: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [step, setStep] = useState(0);
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userData, setUserData] = useState<any>(null);

  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const handleFileChange = (info: any) => {
    const { status, originFileObj } = info.file;
    if (status === "done") {
      setFile(originFileObj);
      notification.success({
        message: `${info.file.name} file uploaded successfully.`,
      });
    } else if (status === "error") {
      notification.error({ message: `${info.file.name} file upload failed.` });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!file) {
        notification.error({
          message: "Please upload a file before submitting.",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", userId || "");

      await uploadBookingReq(formData);

      notification.success({ message: "Booking data submitted successfully." });
      form.resetFields();
      setFile(null);
      setStep(0); 
    } catch (error) {
      notification.error({ message: "Error submitting booking data." });
    }
  };

  const handleNext = async () => {
    await form.validateFields(["uploadBooking"]);
    setStep(1);
  };

  const handleBack = () => {
    setStep(0);
  };

  return (
    <div>
      <div className="mb-4">
        <Button type="link" onClick={() => navigate(`/booking-request-finx/${userId}`)}>
          {"< Back to Booking request"}
        </Button>
        <h2 className="text-xl font-normal">
          Upload Booking for:{" "}
          {userData && userData.firstName
            ? `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName
                .trim()
                .slice(1)
                .toLowerCase()}`
            : "Loading..."}
        </h2>
      </div>

      <div className="w-full p-8 bg-white shadow-md rounded-xl">
        <Steps current={step} className="mb-8">
          <Steps.Step title="Upload File" />
          <Steps.Step title="Submit" />
        </Steps>

        <Spin spinning={isLoading}>
          <div className="flex justify-center items-center">
            {step === 0 && (
              <Form form={form} layout="vertical">
                <Form.Item
                  name="uploadBooking"
                  className="m-5"
                  rules={[{ required: true, message: "Please upload a file!" }]}
                >
                  {!file ? (
                    <Dragger
                      name="file"
                      className="m-5"
                      multiple={false}
                      customRequest={({ file, onSuccess }) => {
                        setTimeout(() => onSuccess && onSuccess("ok"), 0);
                      }}
                      onChange={handleFileChange}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single file upload. Strictly prohibited
                        from uploading company data or other banned files.
                      </p>
                    </Dragger>
                  ) : (
                    <div className="m-5 items-center">
                      <p className="mb-4">
                        File Selected: <strong>{file.name}</strong>
                      </p>
                      <Button className="m-5" onClick={() => setFile(null)}>
                        Remove File
                      </Button>
                    </div>
                  )}
                </Form.Item>
                <div className="flex justify-end">
                  <Button
            
                    onClick={handleNext}
                    disabled={!file}
                  >
                    Next
                  </Button>
                </div>
              </Form>
            )}
            {step === 1 && (
              <div className="flex flex-col items-center">
                <p className="mb-4">
                  File: <strong>{file?.name}</strong>
                </p>
                <div className="flex justify-between w-full">
                  <Button onClick={handleBack}>Back</Button>
                  <Button type="primary" className="ms-5" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default UploadBooking;
