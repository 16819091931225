import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { Button, Divider, Tooltip } from 'antd';
import moment from 'moment';

import { getClientDetails, getInvoiceList } from '../api';
import LoadingPlaceHolder from '../../../CommonComponents/LoadingPlaceHolder';
import { IClientDetailsApiResponse } from '../../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import ZohoDetails from '../ZohoDetails';
import TaxInfoListModal from '../TaxInfoListModal';
import ContactTaxInformation from '../ContactTaxInformation';
import ZohoAddressDetailsModal from '../ZohoAddressDetailsModal';
import GSTInfoModal from '../GSTInfoModal';
import RaiseInvoiceModal from '../RaiseInvoiceModal';
import InvoiceList from '../InvoiceList';
import { IInvoiceList } from '../../../../interfaces/invoiceListAndDetails';

const ClientDetails = () => {
  const { id: clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState<IClientDetailsApiResponse | null>(null);
  const [gstInfoModal, setGstInfoModal] = useState<boolean>(false);
  const [taxInfoListModal, setTaxInfoListModal] = useState<boolean>(false);
  const [taxInformationModal, setTaxInformationModal] = useState<boolean>(false);
  const [addressDetailsModal, setAddressDetailsModal] = useState<boolean>(false);
  const [invoiceModal, setInvoiceModal] = useState<boolean>(false);
  const [invoiceListLoading, setInvoiceListLoading] = useState<boolean>(false);
  const [invoiceListData, setInvoiceListData] = useState<IInvoiceList>({
    count: 0,
    invoiceList: []
  });

  useEffect(() => {
    handleGetClientDetails();
  }, [clientId]);

  const handleGetInvoiceList = async () => {
    if (clientId) {
      getInvoiceList({
        setLoading: setInvoiceListLoading,
        clientId: clientId,
        setData: setInvoiceListData
      });
    }
  };

  const handleGetClientDetails = async () => {
    if (clientId) {
      await getClientDetails({
        clientId: clientId,
        setLoading: setIsLoading,
        setData: setDetailsData
      });

      handleGetInvoiceList();
    }
  };

  return (
    <>
      <div>
        <div className="mb-5 flex gap-2 items-center justify-between flex-wrap">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-bold m-0">Client Details</h2>
            <Tooltip title="Refresh Details">
              <Button
                loading={isLoading}
                onClick={handleGetClientDetails}
                disabled={isLoading}
                icon={<MdRefresh />}
              />
            </Tooltip>
          </div>
          <Button
            onClick={() => setInvoiceModal(true)}
            disabled={isLoading || !detailsData?.clientDetails?.clientId}
            className="primary-button">
            Raise Invoice
          </Button>
        </div>

        {isLoading ? (
          <LoadingPlaceHolder />
        ) : (
          <>
            <div className="bg-white py-2 px-3 rounded shadow flex flex-col gap-2">
              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>1.</b> Client Id
                  </span>
                  <span>:</span>
                </p>
                <GetCopyTextDiv text={detailsData?.clientDetails?.clientId} />
              </div>

              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>2.</b> Zoho Id
                  </span>
                  <span>:</span>
                </p>
                <GetCopyTextDiv text={detailsData?.clientDetails?.zohoId} />
              </div>

              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>3.</b> User Id
                  </span>
                  <span>:</span>
                </p>
                <GetCopyTextDiv text={detailsData?.clientDetails?.userId} />
              </div>

              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>4.</b> User Type{' '}
                  </span>
                  <span>:</span>
                </p>
                <GetCopyTextDiv text={detailsData?.clientDetails?.userType} />
              </div>

              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>5.</b> Company Name{' '}
                  </span>
                  <span>:</span>
                </p>
                <GetCopyTextDiv text={detailsData?.clientDetails?.companyName} />
              </div>

              <div className="flex gap-3">
                <p className="flex gap-1 sm:w-[130px] justify-between">
                  <span>
                    <b>6.</b> Date
                  </span>
                  <span>:</span>
                </p>
                <p>
                  {detailsData?.clientDetails?.createdAt
                    ? moment(detailsData?.clientDetails?.createdAt).format('YYYY-MM-DD, HH:mm:ss')
                    : 'NA'}
                </p>
              </div>
            </div>

            <div className="flex gap-2 flex-wrap items-center mt-5 mb-2">
              <h2 className="text-md font-bold m-0 pr-5">GST Info:</h2>
              <Button
                icon={<FaExternalLinkAlt />}
                type="primary"
                onClick={() => setGstInfoModal(true)}>
                View
              </Button>
            </div>

            <Divider />

            <ZohoDetails detailsData={detailsData} />

            <div className="my-5 flex flex-wrap gap-2">
              <Button type="primary" onClick={() => setTaxInfoListModal(true)}>
                Tax Info
              </Button>
              <Button type="primary" onClick={() => setTaxInformationModal(true)}>
                Contact Tax Information
              </Button>
              <Button type="primary" onClick={() => setAddressDetailsModal(true)}>
                Address Details
              </Button>
            </div>
          </>
        )}
      </div>

      <Divider />

      {clientId && detailsData?.clientDetails?.clientId && (
        <InvoiceList invoiceListData={invoiceListData} isLoading={invoiceListLoading} />
      )}

      <GSTInfoModal
        detailsData={detailsData}
        isModalOpen={gstInfoModal}
        setIsModalOpen={setGstInfoModal}
      />

      <TaxInfoListModal
        detailsData={detailsData}
        taxInfoListModal={taxInfoListModal}
        setTaxInfoListModal={setTaxInfoListModal}
      />

      <ContactTaxInformation
        detailsData={detailsData}
        taxInformationModal={taxInformationModal}
        setTaxInformationModal={setTaxInformationModal}
      />

      <ZohoAddressDetailsModal
        detailsData={detailsData}
        isModalOpen={addressDetailsModal}
        setIsModalOpen={setAddressDetailsModal}
      />

      <RaiseInvoiceModal
        detailsData={detailsData}
        isModalOpen={invoiceModal}
        setIsModalOpen={setInvoiceModal}
      />
    </>
  );
};

export default ClientDetails;
