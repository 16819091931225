import { Typography } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface INavigateLink {
  text: string;
  path: string;
}
const NavigateLink: FC<INavigateLink> = ({ text, path }) => {
  return (
    <div className="flex items-center gap-1 flex-wrap">
      <Link to={path} target="_blank">
        <p className={`m-0 text-blue-500 hover:underline`}>
          <b>{text}</b>
        </p>
      </Link>
      <Typography.Text copyable={{ text: text }} />
    </div>
  );
};

export default NavigateLink;
