import { Divider, Modal } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { IClientDetailsApiResponse } from '../../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { FaPhone } from 'react-icons/fa';

interface IZohoAddressDetails {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  detailsData: IClientDetailsApiResponse | null;
}
const ZohoAddressDetailsModal: FC<IZohoAddressDetails> = ({
  isModalOpen,
  setIsModalOpen,
  detailsData
}) => {
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal width={800} title="Address Details" footer={false} open={isModalOpen} onCancel={onClose}>
      <h2 className="text-sm font-bold m-0 mb-2 underline-offset-8">Shipping Address:</h2>

      <div>
        <div className="flex gap-3">
          <p className="flex w-[100px] justify-between">
            Address ID <span>:</span>
          </p>
          <GetCopyTextDiv text={detailsData?.zohoDetails?.shippingAddress?.addressId} />
        </div>
        <h2 className="text-sm font-bold m-0 mt-1">Address :</h2>
        <div className="pl-2">
          {detailsData?.zohoDetails?.shippingAddress?.address && (
            <p>{`1. ${detailsData?.zohoDetails?.shippingAddress?.address}`}</p>
          )}
          {detailsData?.zohoDetails?.shippingAddress?.street2 && (
            <p>{`2. ${detailsData?.zohoDetails?.shippingAddress?.street2}`}</p>
          )}
        </div>

        <div className="flex gap-1 my-1">
          <span>
            {detailsData?.zohoDetails?.shippingAddress?.city &&
              `${detailsData?.zohoDetails?.shippingAddress?.city},`}
          </span>
          <span>
            {detailsData?.zohoDetails?.shippingAddress?.state &&
              `${detailsData?.zohoDetails?.shippingAddress?.state} (${detailsData?.zohoDetails?.shippingAddress?.stateCode}),`}
          </span>
          <span>
            {detailsData?.zohoDetails?.shippingAddress?.country &&
              `${detailsData?.zohoDetails?.shippingAddress?.country} (${detailsData?.zohoDetails?.shippingAddress?.countryCode}).`}
          </span>
        </div>

        {detailsData?.zohoDetails?.shippingAddress?.phone && (
          <div className="flex gap-2 mb-2 items-center">
            <FaPhone /> <GetCopyTextDiv text={detailsData?.zohoDetails?.shippingAddress?.phone} />
          </div>
        )}
      </div>

      <Divider />

      <h2 className="text-sm font-bold m-0 mb-2 underline-offset-8">Billing Address:</h2>

      <div>
        <div className="flex gap-3">
          <p className="flex w-[100px] justify-between">
            Address ID <span>:</span>
          </p>
          <GetCopyTextDiv text={detailsData?.zohoDetails?.billingAddress?.addressId} />
        </div>

        <h2 className="text-sm font-bold m-0 mt-1">Address :</h2>
        <div className="pl-2">
          {detailsData?.zohoDetails?.billingAddress?.address && (
            <p>{`1. ${detailsData?.zohoDetails?.billingAddress?.address}`}</p>
          )}
          {detailsData?.zohoDetails?.billingAddress?.street2 && (
            <p>{`2. ${detailsData?.zohoDetails?.billingAddress?.street2}`}</p>
          )}
        </div>

        <div className="flex gap-1 my-1">
          <span>
            {detailsData?.zohoDetails?.billingAddress?.city &&
              `${detailsData?.zohoDetails?.billingAddress?.city},`}
          </span>
          <span>
            {detailsData?.zohoDetails?.billingAddress?.state &&
              `${detailsData?.zohoDetails?.billingAddress?.state} (${detailsData?.zohoDetails?.billingAddress?.stateCode}),`}
          </span>
          <span>
            {detailsData?.zohoDetails?.billingAddress?.country &&
              `${detailsData?.zohoDetails?.billingAddress?.country} (${detailsData?.zohoDetails?.billingAddress?.countryCode}).`}
          </span>
        </div>

        {detailsData?.zohoDetails?.billingAddress?.phone && (
          <div className="flex gap-2 mb-2 items-center">
            <FaPhone /> <GetCopyTextDiv text={detailsData?.zohoDetails?.billingAddress?.phone} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ZohoAddressDetailsModal;
