import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Button, Tag } from 'antd';
import { FaDownload } from 'react-icons/fa';
import { ICartListAddressDetails, ICartListPackageDetails, ICartListResponse } from '../../../../interfaces/cartList';
import { DeleteOutlined } from '@ant-design/icons';

export const TableColumns = () => {
  const cartTableColumns: ColumnsType<ICartListResponse> = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      key: 'orderId'
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      key: 'orderType',
      responsive: ['sm'],
      render: (text,record) =>(<h3>
        <Tag color="green"> {record?.orderType}</Tag>
      </h3> )
    },
    {
      title: 'Date',
      dataIndex: 'scheduledAt',
      render: (date) => <p className="w-[120px]">{moment(date).format('DD-MM-YYYY hh:mm:ss')}</p>,
      
    },
    {
        title: (
          <div className="text-center">
            Total <br />
            Packages
          </div>
        ),
        dataIndex: 'packageDetails',
        key: 'packageDetails',
        render: (packages) => <p className="text-center">{packages?.length || 0}</p>
      },
    {
      title: 'Cart Status',
      key: 'cartStatus',
      dataIndex: 'cartStatus'
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Height (cm)
        </div>
      ),
      dataIndex: 'totalHeight',
      render: (height) => (
        <div className="text-center">
          {height || 0}
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Length (cm)
        </div>
      ),
      dataIndex: 'totalLength',
      render: (length) => (
        <div className="text-center">
          {length || 0}
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Width (cm)
        </div>
      ),
      dataIndex: 'totalWidth',
      render: (width) => (
        <div className="text-center">
          {width || 0}
        </div>
      ),
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Weight (g)
        </div>
      ),
      dataIndex: 'totalWeight',
      render: (weight) => (
        <div className="text-center">
          {weight || 0}
        </div>
      ),
    },
    {
      title: 'Document',
      key: 'shipmentLink',
      dataIndex: 'shipmentLink',
      fixed: 'right',
      render: (link) => (
        <Button
          disabled={!link?.length}
          icon={<FaDownload />}
          href={link}
          target="_blank"
          className="primary-button">
          Download
        </Button>
      )
    },
  ];

  const addressDetailsColumns: ColumnsType<ICartListAddressDetails> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      fixed: 'left',
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'City',
      dataIndex: 'city',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'State',
      dataIndex: 'state',
      responsive: ['sm'],
      render: (state) => state ?? 'NA'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      responsive: ['sm'],
      render: (country) => country ?? 'NA'
    },
    {
      title: (
        <div className="text-center">
          Pin <br />
          Code
        </div>
      ),
      dataIndex: 'pinCode',
      responsive: ['sm'],
      render: (data) => data ?? 'NA'
    },
    {
      title: 'Mobile Num',
      dataIndex: 'mobile',
      responsive: ['sm'],
      render: (phone) => (phone ?? 'NA')
    },
    {
      title: 'Alternate Mobile',
      dataIndex: 'alternateMobile',
      responsive: ['sm'],
      render: (phone) => (phone ?? 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      responsive: ['sm'],
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<ICartListPackageDetails> = [
    {
        title: (
          <div className="text-center">
            Package <br /> Num
          </div>
        ),
        dataIndex: 'packageDetails',
        fixed: 'left',
        render: (packageNumber) => packageNumber?.length ?? 'NA'
      },
      {
        title: 'Category',
        dataIndex: 'itemCategory',
        render: (category) => category ?? 'NA'
      },
      {
        title: 'Description',
        dataIndex: 'itemDescription',
        render: (description) => description ?? 'NA'
      },
      {
        title: 'Estimated Value',
        dataIndex: 'estimatedValue',
        render: (value) => (value ? `₹ ${value}` : 'NA')
      },
      {
        title: 'Weight (g)',
        dataIndex: 'weight',
      },
      {
        title: 'Length (cm)',
        dataIndex: 'length',
      },
      {
        title: 'Height (cm)',
        dataIndex: 'height',
      },
      {
        title: 'Width (cm)',
        dataIndex: 'width',
      }
    ];
  return { cartTableColumns, addressDetailsColumns, packageDetailsColumns };
};
