import React, { FC, useState } from 'react';
import { Button, Modal, Radio, RadioChangeEvent, Spin, Tooltip } from 'antd';
import { doc, onSnapshot } from '@firebase/firestore';

import { db } from '../../../../firebase/firebase';
import { MdOpenInNew } from 'react-icons/md';
import Formlabel from '../../../CommonComponents/FormLabel';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { generateReceipt } from '../api';
import { FaDownload } from 'react-icons/fa';

interface IGenerateReceiptButton {
  id: string;
  userId: string;
}
const GenerateReceiptButton: FC<IGenerateReceiptButton> = ({ id, userId }) => {
  const [generateModal, setGenerateModal] = useState<boolean>(false);
  const [isCustomerCopy, setIsCustomerCopy] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [receiptCounts, setReceiptCounts] = useState<{
    currentCount: number;
    totalCount: number;
  }>({
    currentCount: 0,
    totalCount: 0
  });

  const onGenerateClick = () => {
    setGenerateModal(true);
  };

  const onSubmit = async () => {
    const payload = {
      id: id,
      userId: userId,
      customerCopy: isCustomerCopy
    };

    setReceiptCounts({
      currentCount: 0,
      totalCount: receiptCounts?.totalCount
    });

    ecboReceiptRealtimeCounts(id);

    await generateReceipt({
      setLoading: setGenerateLoading,
      payload,
      callBackFunc: onClose
    });
  };

  const onClose = () => {
    setGenerateModal(false);
  };

  const handleCheckboxChange = (e: RadioChangeEvent) => {
    setIsCustomerCopy(e.target.checked);
  };

  const ecboReceiptRealtimeCounts = async (id: string) => {
    const docRef = doc(db, 'ongoingProcess', `ECBO-Receipt-${id}`);

    setGenerateLoading(true);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setReceiptCounts({
            currentCount: snapshot.data()?.currentCount,
            totalCount: snapshot.data()?.totalCount
          });
        }
      },
      (error) => {
        console.error('Error fetching Firestore document:', error);
        setGenerateLoading(false);
      }
    );

    return unsubscribe;
  };

  const getTitle = (defaultText = 'Generate') => {
    return generateLoading
      ? `${receiptCounts?.currentCount ? receiptCounts?.currentCount : 0} / ${
          receiptCounts?.totalCount
        }`
      : defaultText;
  };

  return (
    <>
      <Tooltip title={generateLoading ? getTitle() : ''}>
        <Button
          disabled={generateLoading}
          loading={generateLoading}
          onClick={onGenerateClick}
          icon={<MdOpenInNew />}>
          {getTitle()}
        </Button>
      </Tooltip>

      <Modal title="Generate Receipt" open={generateModal} onCancel={onClose} footer={false}>
        <div className="flex gap-3 mt-5">
          <p className="w-[70px] flex gap-1 justify-between">
            ID <span>:</span>
          </p>
          <GetCopyTextDiv text={id} />
        </div>

        <div className="flex gap-3 my-3">
          <p className="w-[70px] flex gap-1 justify-between">
            User Id <span>:</span>
          </p>
          <GetCopyTextDiv text={userId} />
        </div>

        <div className="flex flex-col gap-1">
          <Formlabel labelText={<p className="font-semibold">Receipt Type :</p>} />
          <Radio.Group defaultValue={isCustomerCopy} onChange={handleCheckboxChange}>
            <Radio value={false}>DBP</Radio>
            <Radio value={true}>Customer Copy</Radio>
          </Radio.Group>
        </div>

        <div className="flex justify-end gap-2 mt-5">
          <Button disabled={generateLoading} onClick={onClose}>
            Cancel
          </Button>

          <Tooltip title={generateLoading ? getTitle() : ''}>
            <Button
              disabled={generateLoading}
              loading={generateLoading}
              className={`primary-button flex items-center ${
                generateLoading ? 'text-red-400' : ''
              }`}
              icon={<FaDownload />}
              onClick={onSubmit}>
              {getTitle('Download')}
            </Button>
          </Tooltip>
        </div>

        <div className="flex justify-center mt-5 text-blue-800">
          {generateLoading ? <b>{`Please wait, generated ${getTitle('')} receipts.`}</b> : ''}
        </div>
      </Modal>
    </>
  );
};

export default GenerateReceiptButton;
