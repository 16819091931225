import { Button, Divider, Form, FormInstance, Input } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { addNewClientFormValidations } from '../constants';
import { verifyUserApi } from '../api';
import { IVerifyUserForm } from '../../../../interfaces/invoicingEngine';

interface IVerifyUser {
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
  formInstance: FormInstance<IVerifyUserForm>;
}

const VerifyUser: FC<IVerifyUser> = ({ userId, setUserId, formInstance }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (formValues: { userEmail: string }) => {
    await verifyUserApi({
      setLoading: setIsLoading,
      emailId: formValues?.userEmail,
      callbackFun: onSetUserId
    });
  };

  const onSetUserId = (userId: string) => {
    setUserId(userId);
  };

  return (
    <>
      {!userId && <p className="text-sm font-bold">Verify User</p>}
      <Form form={formInstance} id="verifyUserEmail" onFinish={onSubmit} disabled={isLoading}>
        <div className="w-full pt-2">
          <Formlabel labelText="Email" required />
          <Form.Item
            className="mt-2"
            name="userEmail"
            rules={[
              {
                required: true,
                message: addNewClientFormValidations?.userEmail?.required
              },
              {
                pattern: addNewClientFormValidations?.userEmail?.patternValueRegex,
                message: addNewClientFormValidations?.userEmail?.patternMessage
              }
            ]}>
            <Input disabled={!!userId} type="text" placeholder="Enter email id" />
          </Form.Item>

          {!userId && (
            <div className="text-center">
              <Button loading={isLoading} form="verifyUserEmail" type="primary" htmlType="submit">
                Verify
              </Button>
            </div>
          )}
        </div>

        <Divider />
      </Form>
    </>
  );
};

export default VerifyUser;
