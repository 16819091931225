import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { MdRefresh } from 'react-icons/md';
import { FaExternalLinkAlt, FaPlusCircle } from 'react-icons/fa';
import moment from 'moment';

import { getClientList } from './api';
import { IClientList, IClientListItem } from '../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../CommonComponents/GetCopyTextDiv';
import AddNewClientForm from './AddNewClientForm';
import NavigateLink from '../../CommonComponents/NavigateLink';

const InvoicingEngine = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [clientsList, setClientsList] = useState<IClientList>({
    count: 0,
    clientsList: []
  });
  const [addClientModal, setAddClientModal] = useState<boolean>(false);

  const handleGetClientsList = async () => {
    await getClientList({ setLoading: setIsTableLoading, setData: setClientsList });
  };

  useEffect(() => {
    handleGetClientsList();
  }, []);

  const tableColumns: ColumnsType<IClientListItem> = [
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      fixed: 'left',
      width: 180,
      render: (id) => (id ? <NavigateLink text={id} path={`/invoicing-engine/${id}`} /> : 'NA')
    },
    {
      title: 'Company Name',
      width: 160,
      dataIndex: 'companyName',
      render: (name) => <GetCopyTextDiv text={name} />
    },
    {
      title: 'Zoho ID',
      dataIndex: 'zohoId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'User ID',
      width: 200,
      dataIndex: 'userId',
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'User Type',
      width: 160,
      dataIndex: 'userType',
      render: (type) => <GetCopyTextDiv text={type} />
    },
    {
      title: 'Date',
      width: 200,
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('DD-MM-YYYY')
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: 90,
      fixed: 'right',
      render: (website) => (
        <Tooltip title={!website && 'Not found'}>
          <Button
            disabled={!website}
            onClick={() => window.open(website, '_blank')}
            type="link"
            icon={<FaExternalLinkAlt />}
          />
        </Tooltip>
      )
    }
  ];

  return (
    <>
      <div className="flex gap-5 justify-between items-center mb-5">
        <div className="flex gap-2 items-center">
          <h2 className="text-xl font-normal m-0">Invoicing Engine</h2>
          <Tooltip title="Refresh List">
            <Button onClick={handleGetClientsList} loading={isTableLoading} icon={<MdRefresh />} />
          </Tooltip>
        </div>

        <Button
          icon={<FaPlusCircle />}
          onClick={() => setAddClientModal(true)}
          className="primary-button">
          Add
        </Button>
      </div>

      <Table
        scroll={{ x: 1200 }}
        loading={isTableLoading}
        columns={tableColumns}
        pagination={false}
        dataSource={clientsList?.clientsList}
      />

      <AddNewClientForm
        isModalOpen={addClientModal}
        setIsModalOpen={setAddClientModal}
        handleGetClientsList={handleGetClientsList}
      />
    </>
  );
};

export default InvoicingEngine;
