import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import { showMessage } from '../../../../utils/ResponseMessages';
import { IGenerateBulkShipmentLabel, IInvoiceResponse } from '../../../../interfaces/generateDocs';

export const generateInvoice = async ({
  setLoading,
  orderId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderId: string;
  setData: Dispatch<SetStateAction<IInvoiceResponse | undefined>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/generate/invoice/${orderId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(undefined);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const generateLabel = async ({
  setLoading,
  orderId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderId: string;
  setData: Dispatch<SetStateAction<IInvoiceResponse | undefined>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/generate/label/${orderId}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(undefined);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const generateBulkLabel = async ({
  setLoading,
  orderIds,
  batchId,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  orderIds: string[];
  batchId: string;
  setData: Dispatch<SetStateAction<IGenerateBulkShipmentLabel | undefined>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.post(`/generate/label`, { orderIds, batchId });
    setData(response?.data?.data);
  } catch (error: any) {
    setData(undefined);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const generationCount = async ({
  setLoading
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient?.get('/generate/batchId/Label');
    return response?.data?.data?.batchId;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    return undefined;
  } finally {
    setLoading(false);
  }
};
