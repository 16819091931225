import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { getUserDetails, pickupAddressList } from "../api";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import GetCopyTextDiv from "../../../CommonComponents/GetCopyTextDiv";
import PickupAddressDrawer from "./PickupAddDrawer";

const Address: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [pickupAddressListLoading, setPickupAddressListLoading] = useState<boolean>(false);
  const [pickupAddressData, setPickupAddressData] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(30);
  const [visible, setVisible] = useState(false); 
  const [users, setUsers] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }]);

  useEffect(() => {
    getAddress(); 
  }, [userId]);

  const getAddress = async () => {
    try {
      if (!userId) return; 
      const pickupAddressListApiRes = await pickupAddressList({ pageNumber, pageLimit, userId });
      console.log(pickupAddressListApiRes, "API Response");
      setPickupAddressData(pickupAddressListApiRes.list); 
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };
  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        console.log("User Details:", response);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };
  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      refreshData();
    }
  }, [userId]);

  const refreshData = async () => {
    if (userId) {
      setPickupAddressListLoading(true);
      await getAddress();
      setPickupAddressListLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <h3 className="font-bold text-blue-500">{record?.name}</h3>
          <GetCopyTextDiv text={record?.email} />
        </>
      ),
      responsive: ["sm"],
    },
    {
      title: 'Address Id',
      dataIndex: 'addressId',
      key: 'addressId',
      responsive: ['sm'],
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
      responsive: ['sm'],
      render: (text,record) =>(<h3>
        {record?.mobile}
      </h3> )
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
     render: (text,record) => (
        <>
          <h3>{record?.line1}</h3>
          <h3>{record?.line2}</h3>
          <h3>{record?.line3}</h3>
          <h3>{record?.city}</h3>
          <h3>{record?.state}</h3>
        </>
      ),
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      responsive: ['sm'],
      render: (text,record) => (
        <>
          <h3>{record?.companyName}</h3>
        </>
      )
    },
    {
      title: "Pin Code",
      dataIndex: "pinCode",
      key: "address",
     render: (text,record) => (
        <>
          <h3>{record?.pinCode}</h3>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button type="link" onClick={() => navigate("/userlist-finx")}>
        {"<"}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="mb-4 flex items-center justify-between">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center">
        <h2 className="text-xl font-normal m-0">
        Address of User:{" "}
          {userData && userData.firstName ? (
                `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
          ) : (
            "Loading..."
          )}
        </h2>
        </div>
      </div>
        <div className="flex items-center">
          <Button size="large" onClick={refreshData} className="mr-8" icon={<ReloadOutlined className="" />}>
            Refresh
          </Button>
          <Button size="large" className="mr-8" onClick={() => setVisible(true)}>
            Add Address
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={pickupAddressData}
        loading={pickupAddressListLoading}
        rowKey={(record) => record.id} 
      />
       <PickupAddressDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        userId={userId || "--"}
        refreshData={refreshData}
        users={users}
      />
    </div>
    
  );
};

export default Address;
