import { Button, Form, PaginationProps, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import useOrderListDetails from '../../api';
import GetStatus from '../../../../../utils/GetStatus';
import DateRangeFilter from '../../../../CommonComponents/TableFilterComponents/DateRangeFilter';
import RefundActions from '../RefundActions';
import { ROLE_FOR_REFUND_ACTIONS } from '../../../../../utils/config';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import { getAccessPermission } from '../../../../../utils/getAccessPermission';
import GetOrderByIdFilter from '../../../../CommonComponents/TableFilterComponents/GetOrderById';
import { SearchOutlined } from '@ant-design/icons';
import YellowCircle from '../../../../CommonComponents/YellowCircle';

const RefundedOrdersList: React.FC = () => {
  const [orderIdSearchForm] = Form.useForm();
  const [refundStatusForm] = Form.useForm();
  const [paymentIdSearchForm] = Form.useForm();

  const {
    getRefundOrdersList,
    refundOrdersData,
    isLoading,
    getRefundOrderDetails,
    refundDetailsData
  } = useOrderListDetails();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [refundActionModal, setRefundActionModal] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [executeOrder, setExecuteOrder] = useState(false);
  const [orderId, setOrderId] = useState<string>('');
  const [paymentId, setPaymentId] = useState<string>('');
  const [refundStatus, setRefundStatus] = useState('');

  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const { tokenData } = getTokenDetails();

  const [awbIdSearchForm] = Form.useForm();
  const [awbId, setAwbId] = useState('');

  const handleGetRefundList = async () => {
    await getRefundOrdersList({
      pageNumber: currentPage,
      pageLimit: pageLimit,
      from: dateRange?.from,
      to: dateRange?.to,
      orderId: orderId,
      paymentId: paymentId,
      refundStatus: refundStatus,
      awbId: awbId
    });
  };

  useEffect(() => {
    handleGetRefundList();
  }, [currentPage, pageLimit, dateRange, orderId, paymentId, refundStatus, awbId]);

  // Refund Status Functions
  const handleStatusSearch = ({ refundStatus }: { refundStatus: string }) => {
    setRefundStatus(refundStatus);
  };
  const handleStatusReset = () => {
    setRefundStatus('');
    refundStatusForm.resetFields();
  };

  const getRefundStatus = () => ({
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={refundStatusForm}
          layout="vertical"
          id="refundStatusForm"
          onFinish={handleStatusSearch}>
          <Form.Item name="refundStatus">
            <Select
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 180 }}
              options={[
                {
                  value: 'initiated',
                  label: 'INITIATED'
                },
                {
                  value: 'approved',
                  label: 'APPROVED'
                },
                { value: 'refunded', label: 'REFUNDED' },
                {
                  value: 'declined',
                  label: 'DECLINED'
                }
              ]}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleStatusReset} size="small">
            Reset
          </Button>
          <Button htmlType="submit" form="refundStatusForm" size="small" className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  });

  const handleGetOrderDetails = async (isAction: boolean, orderId: string) => {
    await getRefundOrderDetails({
      callbackFun: () => {
        isAction ? setRefundActionModal(true) : setShowDetails(true);
      },
      orderId
    });
  };

  let columns: ColumnsType<any> = [
    {
      dataIndex: 'paymentId',
      key: 'paymentId',
      width: 250,
      ...GetOrderByIdFilter({
        orderId: paymentId,
        setOrderId: setPaymentId,
        formInstance: paymentIdSearchForm,
        formId: 'paymentIdSearchForm',
        title: 'Payment Id',
        placeHolder: 'Payment Id'
      })
    },
    {
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...DateRangeFilter({ setDaterange: setDateRange, daterange: dateRange })
    },

    {
      dataIndex: 'orderId',
      key: 'orderId',
      filterSearch: true,
      width: 200,
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: orderIdSearchForm,
        formId: 'orderIdSearchForm'
      })
    },
    {
      width: 200,
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      ...GetOrderByIdFilter({
        orderId: awbId,
        setOrderId: setAwbId,
        formInstance: awbIdSearchForm,
        title: 'AWB #',
        placeHolder: 'AWB #',
        formId: 'AwbIdForm'
      })
    },
    {
      title: 'Refund Speed',
      dataIndex: 'refundSpeed',
      key: 'refundSpeed',
      render: (value) => value?.toUpperCase(),
      width: 200
    },
    {
      title: 'Billed Amount',
      dataIndex: 'billedAmount',
      key: 'billedAmount',
      render: (amount) => `₹ ${amount}`,
      width: 200
    },
    {
      title: 'Refund Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      render: (amount) => `₹ ${amount}`
    },
    {
      title: (
        <div className="flex items-center">
          Refunded Status
          {refundStatus ? <YellowCircle /> : null}
        </div>
      ),
      dataIndex: 'refundStatus',
      key: 'refundStatus',
      width: 200,
      render: (value, order) => (
        <GetStatus
          currStatus={value}
          PROCESSING="initiated"
          SUCCESS="approved"
          FAILED="declined"
          COMPLETED="refunded"
          CUSTOM_LABEL={value?.toUpperCase()}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      ...getRefundStatus()
    },
    {
      title: 'Summary',
      key: 'summary',
      width: 150,
      render: (value, order) => (
        <Button className="m-0" onClick={() => handleGetOrderDetails(false, order?.orderId)}>
          View
        </Button>
      )
    }
  ];

  if (
    getAccessPermission({
      allowedPermissions: ROLE_FOR_REFUND_ACTIONS,
      userPermissions: tokenData?.role || []
    })?.length
  ) {
    columns = [
      ...columns,
      {
        title: 'Action',
        width: 200,
        render: (paymentId, order) => (
          <div>
            {paymentId?.refundStatus === 'initiated' && (
              <div className="flex gap-3">
                <Button
                  className="primary-button"
                  onClick={() => handleGetOrderDetails(true, order?.orderId)}>
                  Action
                </Button>
              </div>
            )}

            {paymentId.refundStatus === 'approved' && (
              <Button
                className="primary-button"
                onClick={() => {
                  setExecuteOrder(true);
                  handleGetOrderDetails(true, order?.orderId);
                }}>
                Execute
              </Button>
            )}
          </div>
        )
      }
    ];
  }

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const handleOnClose = () => {
    setExecuteOrder(false);
  };

  return (
    <div>
      <div className="flex items-center mb-4 gap-4 justify-between">
        <div className="flex gap-3">
          <h2 className="text-xl font-normal m-0">Refunded Orders List</h2>
          {!!refundOrdersData?.totalDocument && (
            <h2 className="text-xl font-normal m-0">
              {`( ${refundOrdersData?.currentDocument} / ${refundOrdersData?.totalDocument} )`}
            </h2>
          )}
        </div>

        <Button className="m-0 primary-button" onClick={handleGetRefundList}>
          Refresh
        </Button>
      </div>
      <Table
        columns={columns}
        loading={isLoading}
        scroll={{ x: 1600 }}
        rowKey={'_id'}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: refundOrdersData?.totalPage ? refundOrdersData?.totalPage * pageLimit : 0,
          onChange: onPageChange
        }}
        dataSource={refundOrdersData?.refundList}
      />

      <RefundActions
        refundActionModal={refundActionModal}
        setRefundActionModal={setRefundActionModal}
        refundData={refundDetailsData}
        showActionButtons={true}
        isExecuteOnly={executeOrder}
        onClose={handleOnClose}
        callBackFunction={handleGetRefundList}
      />

      {/* modal to show the refund details  */}
      <RefundActions
        refundActionModal={showDetails}
        setRefundActionModal={setShowDetails}
        refundData={refundDetailsData}
        showActionButtons={false}
      />
    </div>
  );
};

export default RefundedOrdersList;
