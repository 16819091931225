import React, { FC, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
  IInvoiceDetails,
  IInvoiceDetailsItems
} from '../../../../interfaces/invoiceListAndDetails';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import ItemTaxesModal from '../ItemTaxesModal';

interface IInvoiceItemDetailsProps {
  responseData: IInvoiceDetails | null;
}
const InvoiceItemDetails: FC<IInvoiceItemDetailsProps> = ({ responseData }) => {
  const [itemTaxesModal, setItemTaxesModal] = useState<boolean>(false);
  const [itemTaxesData, setItemTaxesData] = useState<IInvoiceDetailsItems | null>(null);

  const columns: ColumnsType<IInvoiceDetailsItems> = [
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Account Name',
      width: 150,
      dataIndex: 'accountName',
      render: (name) => <GetCopyTextDiv text={name} />
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      width: 200,
      render: (num) => <GetCopyTextDiv text={num} />
    },
    {
      title: 'Description',
      width: 200,
      dataIndex: 'description'
    },
    {
      title: 'Item Taxes',
      width: 110,
      fixed: 'right',
      dataIndex: 'itemTaxes',
      render: (_, data) => (
        <Button
          onClick={() => handleOnClickItemTaxes(data)}
          icon={<FaExternalLinkAlt color="blue" />}
        />
      )
    }
  ];

  const handleOnClickItemTaxes = (data: IInvoiceDetailsItems) => {
    setItemTaxesData(data);
    setItemTaxesModal(true);
  };

  return (
    <>
      <h2 className="text-lg font-bold m-0 mb-5">Items :</h2>
      <Table scroll={{ x: 800 }} columns={columns} dataSource={responseData?.items} />

      <ItemTaxesModal
        itemTaxesData={itemTaxesData}
        setItemTaxesData={setItemTaxesData}
        isModalOpen={itemTaxesModal}
        setIsModalOpen={setItemTaxesModal}
      />
    </>
  );
};

export default InvoiceItemDetails;
