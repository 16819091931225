import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Button, Divider, Steps, Tooltip } from 'antd';
import BusinessDetails from './BusinessDetails';
import PickupAddress from './PickupAddress';
import {
  IBulkUploadUrl,
  ICheckAvailabilityResponse,
  IPickupAddressItem,
  IValidatedResponse
} from '../../../../interfaces/b2bBulkOrder';
import BulkUploadFile from './BulkUploadFile';
import AdditionalDetails from './AdditionalDetails';
import RatesServiceability from './RatesServiceability';
import ResetBookingModal from './ResetBookingModal';
import ProviderPreferenceModal from './ProviderPreferenceModal';
import { SAMPLE_BULK_FILE } from './constants';
import { FaDownload } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';

const B2BBulkOrder = () => {
  const initialState = {
    currentStep: 0,
    businessId: '',
    selectedAddress: null,
    priority: 'CHEAPEST',
    rateCategory: 'STANDARDIZED',
    isUploadSuccess: false,
    validatedResponse: null,
    bulkUploadData: null,
    componentKey: 0,
    summaryData: null
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [currentStep, setCurrentStep] = useState<number>(initialState.currentStep);

  // NOTE: states for first step
  const [businessId, setBusinessId] = useState<string>(initialState.businessId);

  // NOTE: states for second step
  const [selectedAddress, setSelectedAddress] = useState<IPickupAddressItem | null>(
    initialState.selectedAddress
  );

  // NOTE: states for third step
  const [priority, setPriority] = useState<string>(initialState.priority);
  const [rateCategory, setRateCategory] = useState<string>(initialState.rateCategory);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(initialState.isUploadSuccess);
  const [validatedResponse, setValidatedResponse] = useState<IValidatedResponse | null>(
    initialState.validatedResponse
  );
  const [bulkUploadData, setBulkUploadData] = useState<IBulkUploadUrl | null>(
    initialState.bulkUploadData
  );
  const [summaryData, setSummaryData] = useState<ICheckAvailabilityResponse | null>(
    initialState.summaryData
  );
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [validateLoading, setValidateLoading] = useState<boolean>(false);

  // NOTE: states for reset booking flow
  const [componentKey, setComponentKey] = useState<number>(initialState.componentKey);
  const [resetModal, setResetModal] = useState<boolean>(false);

  const [providerPreferenceModal, setProviderPreferenceModal] = useState<boolean>(false);

  const onClickReset = () => {
    setCurrentStep(initialState.currentStep);
    setBusinessId(initialState.businessId);
    setSelectedAddress(initialState.selectedAddress);
    setPriority(initialState.priority);
    setRateCategory(initialState.rateCategory);
    setIsUploadSuccess(initialState.isUploadSuccess);
    setValidatedResponse(initialState.validatedResponse);
    setBulkUploadData(initialState.bulkUploadData);
    setSummaryData(initialState.summaryData);
    setComponentKey((prev) => prev + 1);
    setResetModal(false);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileType(null);
    setError(null);
    setBulkUploadData(null);
    setIsUploadSuccess(false);
    setValidatedResponse(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const resetToFirstStep = () => {
    setCurrentStep(0);
    setSelectedAddress(initialState.selectedAddress);
    resetToSecondStep(false);
  };

  const resetToSecondStep = (resetStep = true) => {
    if (resetStep) {
      setCurrentStep(1);
    }
    setPriority(initialState.priority);
    setRateCategory(initialState.rateCategory);
    handleRemoveFile();
    setSummaryData(initialState.summaryData);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setValidateLoading(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-normal m-0 mb-4">Bulk Orders</h2>

        <div className="flex gap-2">
          <Button className="primary-button" onClick={() => setProviderPreferenceModal(true)}>
            Provider Preference
          </Button>
          <Tooltip title="Download Sample Bulk Order File">
            <Button
              icon={<FaDownload />}
              type="primary"
              onClick={() => window.open(SAMPLE_BULK_FILE, '_blank')}>
              Sample File
            </Button>
          </Tooltip>
        </div>
      </div>

      <ResetBookingButton setResetModal={setResetModal} />

      <Steps
        direction="vertical"
        current={currentStep}
        className="max-w-[950px]"
        items={[
          {
            title: (
              <div className="flex items-center gap-2">
                <h2 className="text-xl">Select Business</h2>
                {currentStep > 0 && (
                  <Button onClick={resetToFirstStep} icon={<FaEdit size={20} />} />
                )}
              </div>
            ),
            description: (
              <>
                <div className="border border-gray-400 min-h-[200px] rounded-xl my-2">
                  <BusinessDetails
                    compKey={`componentKey_1_${componentKey}`}
                    disabledStep={!(currentStep === 0)}
                    nextStep={nextStep}
                    setBusinessId={setBusinessId}
                  />
                </div>
                <Divider />
              </>
            )
          },
          {
            title: (
              <div className="flex items-center gap-2">
                <h2 className="text-xl">Select Pickup Address</h2>
                {currentStep > 1 && (
                  <Button onClick={() => resetToSecondStep()} icon={<FaEdit size={20} />} />
                )}
              </div>
            ),
            disabled: !businessId || !(currentStep === 1),
            description: (
              <>
                <div className="border border-gray-400 rounded-xl my-2">
                  <PickupAddress
                    key={`componentKey_2_${componentKey}`}
                    businessId={businessId}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    nextStep={nextStep}
                    disableStep={!businessId || !(currentStep === 1)}
                  />
                </div>
                <Divider />
              </>
            )
          },
          {
            title: <h2 className="text-xl">Upload Bulk File</h2>,
            disabled: !businessId || !(currentStep === 2),
            description: (
              <>
                <div className="border border-gray-400 rounded-xl my-2">
                  <AdditionalDetails
                    key={`componentKey_3_${componentKey}`}
                    disableStep={!businessId || !(currentStep >= 2)}
                    priority={priority}
                    setPriority={setPriority}
                    rateCategory={rateCategory}
                    setRateCategory={setRateCategory}
                    isUploadSuccess={isUploadSuccess}
                  />

                  <BulkUploadFile
                    compKey={`componentKey_4_${componentKey}`}
                    disabledStep={!businessId || !(currentStep === 2)}
                    priority={priority}
                    businessId={businessId}
                    rateCategory={rateCategory}
                    selectedAddress={selectedAddress}
                    isUploadSuccess={isUploadSuccess}
                    setIsUploadSuccess={setIsUploadSuccess}
                    validatedResponse={validatedResponse}
                    setValidatedResponse={setValidatedResponse}
                    nextStep={nextStep}
                    bulkUploadData={bulkUploadData}
                    setBulkUploadData={setBulkUploadData}
                    fileInputRef={fileInputRef}
                    file={file}
                    setFile={setFile}
                    fileType={fileType}
                    setFileType={setFileType}
                    error={error}
                    setError={setError}
                    handleRemoveFile={handleRemoveFile}
                    validateLoading={validateLoading}
                    setValidateLoading={setValidateLoading}
                  />
                </div>
                <Divider />
              </>
            )
          },
          {
            title: <h2 className="text-xl">Rates & Serviceability</h2>,
            disabled:
              !businessId ||
              !(currentStep === 3) ||
              !validatedResponse?.noValidationErrors ||
              !bulkUploadData,
            description: (
              <>
                <div className="border border-gray-400 rounded-xl my-2">
                  {!businessId ||
                  !(currentStep === 3) ||
                  !validatedResponse?.noValidationErrors ||
                  !bulkUploadData ? (
                    <p className="px-3 py-4"> Details are available once validated</p>
                  ) : (
                    <>
                      <RatesServiceability
                        key={`componentKey_5_${componentKey}`}
                        bulkUploadData={bulkUploadData}
                        summaryData={summaryData}
                        setSummaryData={setSummaryData}
                      />

                      <ResetBookingButton setResetModal={setResetModal} />
                    </>
                  )}
                </div>
                <Divider />
              </>
            )
          }
        ]}
      />

      <ResetBookingModal
        onClickReset={onClickReset}
        isModalOpen={resetModal}
        setIsModalOpen={setResetModal}
      />

      <ProviderPreferenceModal
        isOpenModal={providerPreferenceModal}
        setIsOpenModal={setProviderPreferenceModal}
      />
    </>
  );
};

export default B2BBulkOrder;

export const ResetBookingButton = ({
  setResetModal
}: {
  setResetModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex justify-end mt-5 pb-4 pr-3">
      <Button
        className="bg-red-500 !text-white font-semibold border-none"
        onClick={() => setResetModal(true)}>
        Reset Booking
      </Button>
    </div>
  );
};
